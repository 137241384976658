export const AUTH_SHOW_PRIVACY_PAGE = 'AUTH_SHOW_PRIVACY_PAGE';
export const AUTH_OTP_PAGE = 'AUTH_OTP_PAGE';
export const AUTH_ACTIVATION_PAGE = 'AUTH_ACTIVATION_PAGE';
export const AUTH_ACTIVATION_OTP_PAGE = 'AUTH_ACTIVATION_OTP_PAGE';
export const AUTH_ACTIVATION_BLOCKED = 'AUTH_ACTIVATION_BLOCKED';

export const OTP_BIOMETRICS_VIEW = 'OTP_BIOMETRICS_VIEW'; // Nell'otp per mostrare vista con faceid/impronta
export const OTP_STANDARD_VIEW = 'OTP_STANDARD_VIEW'; // nell'otp per mostrare la vista standard con passcode
export const OTP_NOT_PASSED_VIEW = 'OTP_NOT_PASSED_VIEW'; // nell'otp per mostrare la vista delle biometriche non passate

export const AUTH_SUBSESSION_PAGE = 'AUTH_SUBSESSION_PAGE';
export const SUBSESSION_PAGE_LOADER = 'SUBSESSION_PAGE_LOADER';
export const SUBSESSION_PAGE_PASSWORD = 'SUBSESSION_PAGE_PASSWORD';
export const SUBSESSION_PAGE_TOUCHID = 'SUBSESSION_PAGE_TOUCHID';


export const DOSSIER_TIMELINE_PAGE = 'TIMELINE';
export const DOSSIER_TAGS_PAGE = 'TAGS';
export const DOSSIER_TAG_TIMELINE_PAGE = 'TAG';

export const DOSSIER_SELECT_USER_DISABLED = 'DOSSIER_SELECT_USER_DISABLED';
export const DOSSIER_SELECT_USER_ENABLED_NO_PARENTS = 'DOSSIER_SELECT_USER_ENABLED_NO_PARENTS';
export const DOSSIER_SELECT_USER_ENABLED_WITH_PARENTS = 'DOSSIER_SELECT_USER_ENABLED_WITH_PARENTS';

export const HEADER_EPISODE_VIEW = 'HEADER_EPISODE_VIEW';
export const HEADER_DOCUMENT_VIEW = 'HEADER_DOCUMENT_VIEW';
export const HEADER_FORCE_EPISODE_VIEW = 'HEADER_FORCE_EPISODE_VIEW';
export const HEADER_SEND_BY_EMAIL = 'HEADER_SEND_BY_EMAIL';

export const DOCUMENT_OPENABLE_TYPE = 'DOCUMENT_OPENABLE_TYPE';
export const DOCUMENT_EXPIRED_TYPE = 'DOCUMENT_EXPIRED_TYPE';
export const DOCUMENT_NOT_DOWNLOADABLE_TYPE = 'DOCUMENT_NOT_DOWNLOADABLE_TYPE';

export const DOSSIER_BIOMETRICS_NOT_ACTIVE = 0; // Biometriche non abilitate di default, oppure disabilitate senza premere tasto "don't ask again"
export const DOSSIER_BIOMETRICS_ACTIVATED = 1; // Biometriche abilitate
export const DOSSIER_BIOMETRICS_DEACTIVATED = 2; // Biometriche disabilitate premento il tasto "don't ask again"
export const DOSSIER_BIOMETRICS_NOT_PASSED = 3; // Biometriche non passate temporaneamente


export const CUSTOM_MAX_DIALOG_HEIGTH = 500;
