import $ from 'jquery';
import _ from 'underscore';
import { createToken } from 'api/tokens';
import { _ajaxRest } from 'api/http';
import { isSafari as isSafariLayoutLib } from 'common-lib/layout';
import { getSpidAppToken } from 'api/login';

import { querystringToObject } from 'common-lib/url';

import { TTPolyglot } from "@frontend/tt-polyglot";

import { AUTH_MODE_FROM_SPID } from 'common-lib/constants';

  /**
   * @function _deg2rad
   * @access private
   * @param {Number} deg
   * @returns {Number}
   */
  function _deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  /**
   * Ritorna la distanza tra due punti in km, calcolandola
   * dalla latitudine e longitudine dei due punti
   *
   * @function getDistanceFromLatLonInKm
   * @access public
   * @param {Number} lat1
   * @param {Number} lon1
   * @param {Number} lat2
   * @param {Number} lon2
   * @returns {Number}
   */
  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = _deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = _deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(_deg2rad(lat1)) * Math.cos(_deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km

    return d;
  }

  /**
   * This function return true or false depending if the distance
   * between the starting and the ending point is less or no
   * the distance passed as third parameter
   *
   * @function myPositionIn
   * @access public
   * @param {Number} start - object containing latitude and langitude
   * @param {Number} end - object containing latitude and langitude
   * @param {Number} distance - distance expressed in km
   * @returns {boolean}
   */
  function myPositionIn(start, end, distance) {
    return getDistanceFromLatLonInKm(start.latitude, start.longitude, end.latitude, end.longitude) < distance;
  }

  /**
   * Return true if the portal2privacy config is set in a portal instance.
   * @param  {Object}  param
   * @param  {Object}  param.MOP a reference to the MOP application
   * @return {Boolean}
   */
  function isPortalInstancePrivacyEnabled(param) {
    var MOP = param.MOP;

    return Boolean(Number(MOP.config.getInstanceConfig('portal2privacy'))) && Boolean(MOP.config.getInstanceConfig('isPortal'));
  }

  /**
   * Include a js or css script at the end of the header tag and
   * call the callback function, if passed, on the onload success.
   * The Promise returned if resolved in the onload callback.
   *
   * @function loadjscssfile
   * @param  {String}   filename - filename of js or css type
   * @param  {Function} [callback] - callback called on the onload event
   * @return {Object} - promise resolved in the onload callback function
   */
  function loadjscssfile(filename, callback, errorCallback) {
    var filetype = (filename.indexOf('js') !== -1) ? 'js' : 'css';
    var deferred = new $.Deferred();
    var fileref;

    function errorOnloadCallback () {
    	deferred.resolve(false);

      if (!!errorCallback && typeof errorCallback == 'function') {
        errorCallback();
      }
    }

    function standardOnloadCallback () {
      deferred.resolve(true);

      if (!!callback && typeof callback == 'function') {
        callback();
      }
    }

    if (filetype === 'js') {
      fileref = document.createElement('script');

      fileref.setAttribute('type', 'text/javascript');

      if (callback) {
        if (filename.indexOf('google') !== -1) {
          filename += '&callback=' + callback;
        }
      }

      fileref.setAttribute('src', filename);
      document.body.appendChild(fileref);
    } else if (filetype === 'css') {
      fileref = document.createElement('link');

      fileref.setAttribute('rel', 'stylesheet');
      fileref.setAttribute('type', 'text/css');
      fileref.setAttribute('href', filename);

      if (typeof fileref != 'undefined') {
        document.getElementsByTagName('head')[0].appendChild(fileref);
      }
    }

    fileref.onerror = errorOnloadCallback;

    fileref.onload = fileref.onreadystatechange = standardOnloadCallback;

    return deferred;
  }

  function loadGoogleAPI(MOP, lang) {
    var deferred = new $.Deferred();

    // Se una libreria di google è già stata inclusa nel progetto
    // ritorno immediatamente, visto che questa fallirebbe comunque.
    if (window.google) {
      return deferred.resolve();
    }

    window.MOP_GoogleAPICallback = function () {
      deferred.resolve();
    };

    var errorCallback = function () {
      deferred.reject();
    };
    
    var api_key = MOP.config.getGoogleMapsJavascriptAPIKey(MOP);
    
    loadjscssfile(`https://maps.googleapis.com/maps/api/js?key=${api_key}&sessiontoken=${MOP.localStorage.get("GoogleMapAPISessionToken")}&libraries=places&language=${lang}`, 'MOP_GoogleAPICallback', errorCallback);

    return deferred;
  }


  function loadGoogleCriterionAddressAPI(MOP, lang) {
    var deferred = new $.Deferred();

    // Se una libreria di google è già stata inclusa nel progetto
    // ritorno immediatamente, visto che questa fallirebbe comunque.
    if (window.google) {
      return deferred.resolve();
    }

    window.MOP_GoogleAPICallback = function () {
      deferred.resolve();
    };

    var errorCallback = function () {
      deferred.reject();
    };

    var api_key = MOP.config.getGooglePlacesCriteriaAPIKey(MOP);

    loadjscssfile(`https://maps.googleapis.com/maps/api/js?key=${api_key}&sessiontoken=${MOP.localStorage.get("GoogleMapAPISessionToken")}&libraries=places&language=${lang}`, 'MOP_GoogleAPICallback', errorCallback);

    return deferred;

  }

  function loadGooglePlacesAPI(MOP, lang) {
    var deferred = new $.Deferred();

    // Se una libreria di google è già stata inclusa nel progetto
    // ritorno immediatamente, visto che questa fallirebbe comunque.
    if (window.google) {
      return deferred.resolve();
    }

    window.MOP_GooglePlacesAPICallback = function () {
      deferred.resolve();
    };

    var errorCallback = function () {
      deferred.reject();
    };
    
    var api_key = MOP.config.getGooglePlacesAPIKey(MOP);
    
    loadjscssfile(`https://maps.googleapis.com/maps/api/js?key=${api_key}&libraries=places&sessiontoken=${MOP.localStorage.get("GoogleMapAPISessionToken")}&lang=${lang}`, 'MOP_GooglePlacesAPICallback', errorCallback);
    
    return deferred;
  }

  /**
   * Convert from one order origin to the order target.
   * @param  {Number} value - value to convert
   * @param  {Number} originOrder - origin order
   * @param  {Number} destinationOrder - destination order
   * @return {Number} - converted number
   */
  function convertMeasureOrder(value, originOrder, destinationOrder) {
    if (arguments.length < 3) {
      throw new Error('The parameter has to be 3');
    }

    if (typeof value !== 'number' || typeof originOrder !== 'number' || typeof destinationOrder !== 'number') {
      throw new TypeError('All parameter has to be number');
    }

    return (value * destinationOrder) / originOrder;
  }

  /**
   * Convert a hex color to a rgb color.
   * @param  {String} hex - the hex color
   * @return {Object} - an Object containing three property r,g,b
   */
  function hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
  }

  /**
   * Trigger a custom event on the body element.
   * @param  {Object} data - data to be triggered
   * @return {Boolean} - the result of the event triggering
   */
  function triggerExternalEvent(data) {
    data.type = 'onShow';

    return $('body').trigger(data);
  }

  /**
   * Create an url based on the param.
   * @param {Object} param
   * @param {String} param.hostname - the hostname of the generated URL
   * @param {String} param.pathname - the pathname of the generated URL
   * @param {Object} [param.querystring = {}] - the querystring parameters
   * @return {String}
   */
  function formatURL(param) {
    var MOP = param.MOP;
    var querystring = param.querystring || {};

    var hostname = param.hostname || MOP.config.getRightProtocol() + '://' + MOP.config.get('hostname');

    if (!param) {
      throw new ReferenceError('You can not use this function without parameters.');
    }

    if (!param.MOP) {
      throw new ReferenceError('The "MOP" parameter is missing.');
    }

    if (!param.pathname) {
      throw new ReferenceError('The "pathname" parameter is missing.');
    }

    return hostname + param.pathname + '?' + $.param(querystring);
  }


  function existParamInQueryString (querystring, key) {

    var queryStringObject = querystringToObject(querystring, true);

    if (key in queryStringObject) return true;

    return false;
  }

  function updateQuerystringUrlParameter(uri, key, value) {
    // remove the hash part before operating on the uri
    var i = uri.indexOf('#');
    var hash = i === -1 ? ''  : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";

    if (!value) {
      // remove key-value pair if value is empty
      uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
      if (uri.slice(-1) === '?') {
        uri = uri.slice(0, -1);
      }
      // replace first occurrence of & by ? if no ? is present
      if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
    } else if (uri.match(re)) {
      uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
  }

  /**
   * Convert minutes in hh:mm format.
   * @param {Number} minutes - the minutes to format in hh:mm
   * @return {String}
   */
  function formatTime(minutes){

    if (typeof minutes !== 'number') {
      throw new TypeError('The parameter has to be a number');
    }
    if (minutes < 0) {
      throw new RangeError('The parameter has to be >= 0');
    }
    var hours;
    var mins;
    mins = minutes % 60;
    if(mins < 10){
      mins = "0" +mins;
    }
    hours = Math.floor(minutes / 60);
    if(hours < 10){
      hours = "0" + hours;
    }
    return hours + ":" + mins;
  }

  /**
   * Return a string and adds slashes where need to escape apices
   * @param {String} str - string where add slashes
   * @return {String}
   */
  function addSlashes(str) {
    if (typeof str !== 'string') {
      throw new TypeError('The parameter has to be string');
    }

    if (str != "" && str != "undefined" && str != null){
      //str = str.replace(/\'/g,'&apos;');
      str = str.replace(/\'/g, "\'");
    } else {
      str ="";
    }
    return str;
  }
    /**
   * Check if a variable is empty (and extend the meaning of empty)
   * @param {Any} mixed_var - variable to check
   * @return {Boolean}
   */
  function empty(mixed_var) {
    var key;

    if (mixed_var === "" ||
        mixed_var === 0 ||
        mixed_var === "0" ||
        mixed_var === null ||
        mixed_var === "null" ||
        mixed_var === false ||
        typeof mixed_var === 'undefined' ||
        mixed_var === "undefined"
    ){
        return true;
    }

    if (typeof mixed_var == 'object') {
        for (key in mixed_var) {
          return false;
        }
        return true;
    }
    return false;
  }
    /**
   * Return an object with page and route
   * @param {Object} MOP
   * @param {String} page - the page
   * @param {Object} route - the route
   * @return {Object} - object containing page and route
   */
  function formatPageRoute(MOP, page, route){
    if (!MOP) {

      throw new ReferenceError('The MOP parameter is missing.');
    }

    page = page || MOP.getCurrentPage();

    if (page == 'cancel') {
      page = 'reservations';
      route = 'cancel';
    }

    if (route) {
      var regExp = new RegExp("^" + page + "\/");
      if((route !== page) && (route.match(regExp) === null)) {
        route = page+'/'+route;
      }

    } else {
      route = page;
    }

    return {
      page:  page,
      route: route
    };
  }
    /**
   * Return the format of the date
   * @param {Object} MOP
   * @return {String}
   */
  function getInstanceDateFormat(MOP) {
    if (!MOP) {

        throw new ReferenceError('The MOP parameter is missing.');
    }

    var date_format = MOP.config.getInstanceConfig('dateFormat'),
    validated_format= '';

    switch (date_format){
      case "us":
          validated_format = 'MM/DD/YYYY';
          break;
      case "jp":
          validated_format = 'YYYY/MM/DD';
          break;
      case "eu":
      default:
          validated_format = 'DD/MM/YYYY';
          break;
    }
    return validated_format;
  }

  function dateObjToHumanDateLongFormat (MOP, date) {

    if (!MOP) {
      throw new ReferenceError('The MOP parameter is missing.');
    }

    var date_format = MOP.config.getInstanceConfig('dateFormat');
    var str_date = '';
    let month = MOP.Utilities.getMonthsArray(MOP)[date.getMonth()]
    let day = date.getDate();
    let year = date.getFullYear();

    switch (date_format){
      case "us":
          str_date = month + " " + day + " " + year;
          break;
      case "jp":
          str_date = year + " " + month + " " + day;
          break;
      case "eu":
          str_date = day + " " + month + " " + year;
      default:
          str_date = day + " " + month + " " + year;
          break;
    }

    return str_date;
  }

    /**
   * Return the week day from a date
   * @param {Date} date - the date
   * @param {Any} [short] - if not empty
   * @return {String}
   */
  function getWeekDay(date, short) {
    if (!date) {
      throw new ReferenceError('You can not use this function without parameters.');
    }
    if (!(date instanceof Date)) {
      throw new TypeError('The parameter has to be a Date');
    }

    var weekday = [TTPolyglot.t("Sunday"), TTPolyglot.t("Monday"), TTPolyglot.t("Tuesday"), TTPolyglot.t("Wednesday"), TTPolyglot.t("Thursday"), TTPolyglot.t("Friday"), TTPolyglot.t("Saturday")];
    if( !empty(short) ){
        return (weekday[date.getUTCDay()]).substring(0,3);
    }else{
        return weekday[date.getUTCDay()];
    }
  }

  /**
   * Add days to a date
   * @param {Date} date - the date
   * @param {Number} addDays - the number of days to add
   * @return {Date}
   */
  function addDays(date, addDays) {
    if (!date) {
      throw new ReferenceError('You can not use this function without parameters.');
    }
    if (!(date instanceof Date)) {
      throw new TypeError('The parameter has to be a Date');
    }

    if (typeof addDays !== 'number') {
      throw new TypeError('The parameter has to be a number');
    }

    if (typeof addDays < 0) {
      throw new RangeError('The parameter has to be >= 0');
    }

    return new Date(date.getTime() + (addDays*24*60*60*1000));
  }

  /**
   * Remove days to a date
   * @param {Date} date - the date
   * @param {Number} removeDays - the number of days to remove
   * @return {Date}
   */
  function removeDays(date, removeDays) {
    if (!date) {
      throw new ReferenceError('You can not use this function without parameters.');
    }
    if (!(date instanceof Date)) {
      throw new TypeError('The parameter has to be a Date');
    }

    if (typeof removeDays !== 'number') {
      throw new TypeError('The parameter has to be a number');
    }

    if (typeof removeDays < 0) {
      throw new RangeError('The parameter has to be >= 0');
    }

    return new Date(date.getTime() - (removeDays*24*60*60*1000));
  }

  function getDownloadFileUrl(MOP, params) {

   if (!MOP) {
     throw new ReferenceError('The "MOP" parameter is missing.');
   }

   var querystring = {
       dbName: MOP.getDbName(),
       mobile: MOP.config.isMobile() ? 1 : 0
   };

   var accessPoint = MOP.config.getAccessPointConfig("token")["download_file"];

   params.querystring = _.extend(querystring, params);

   var url = accessPoint + "&" + $.param(params.querystring);

   if (MOP.isFromTRD111()) {
    url += `&auth_mode=${MOP.constants.AUTH_MODE_TRD111}`;
  }

   return url;
 }

 function getDownloadDocumentUrlWithToken(MOP, params) {

  if (!MOP) {
    throw new ReferenceError('The "MOP" parameter is missing.');
  }

  var querystring = {
      dbName: MOP.getDbName(),
      mobile: MOP.config.isMobile() ? 1 : 0
  };

  var accessPoint = MOP.config.getAccessPointConfig("token")["download_document"];

  params.querystring = _.extend(querystring, params);

  var url = accessPoint + "&" + $.param(params.querystring);

  if (MOP.isFromTRD111()) {
    url += `&auth_mode=${MOP.constants.AUTH_MODE_TRD111}`;
  }

  return url;
}

  function getDownloadDocumentUrl(MOP, downloadid, params) {

    if (!MOP) {
      throw new ReferenceError('The "MOP" parameter is missing.');
    }

    createToken(
      MOP.config.constants.GET_TOKEN_ENTITY_DOWNLOAD,
      downloadid,
      MOP.config.constants.GET_TOKEN_DOWNLOAD_YOUR_DOCUMENT
    )
    .then(function (data) {
      var accessPoint = MOP.config.getAccessPointConfig("token")["download_document"];

      var querystring = {
        dbName: MOP.getDbName(),
        mobile: MOP.config.isMobile() ? 1 : 0,
        token: data.token
      };

      querystring = _.extend({}, querystring, params);

      var url = accessPoint + "&" + $.param(querystring);

      if (MOP.isFromTRD111()) {
        url += `&auth_mode=${MOP.constants.AUTH_MODE_TRD111}`;
      }

      return url;
    })
    .catch(function (err) {

    });

  }

  /**
   * @module ApiV3Module
   * Contiene tutte le funzionalità di creazione
   * degli endpoint a cui eseguire chiamate Rest.
   */
  var ApiV3Module = {
    /**
     * Formatta l'URL per eseguire chiamate Rest, occupandosi di inserire
     * @param  {Object} param
     * @param {Object} param.MOP - riferimento al MOP
     * @param {Object} [questystring] - i parametri da mettere in querystring
     * @return {String}
     */
    getRestApi_AjaxUrl: function (MOP, params) {
      if (!MOP) {

          throw new ReferenceError('The MOP parameter is missing.');
      }
      params = params || {};

      var hostname = MOP.config.get('hostname');
      var protocol = 'https://';
      var searchPath = params.searchPath || '';

      if (MOP.config.isLocalHost()) {
          protocol = 'http://';
      } else if (MOP.Utilities.isIE() == 8 || MOP.Utilities.isIE() == 9) {
          protocol = MOP.config.getProtocol() + '//';
      }

      var querystring = params.querystring || {};
      
      // DEPRECATO IN FAVORE DI Authorization Bearer
      // var sessionid = MOP.getSessionid();
      // if( sessionid ){
      //     querystring.sessionid = sessionid;
      // }

      var ajaxDefaultParams = MOP.config.getAjaxDefaultParamsV3(MOP);

      querystring = _.extend(querystring, ajaxDefaultParams);

      querystring = $.param(querystring);

      return protocol + hostname + '/' + MOP.config.get('restapi').subpath + '/' + MOP.config.get('dbName') + '/' + searchPath + '?' + querystring;
    },

    /**
     * Formatta l'oggetto da passare alla $.ajax per eseguire la chiamata Rest
     * @param  {Object} params
     * @param {Object} MOP
     * @param {String} searchPath
     * @param {String} method - http method
     * @param {Boolean} insertAuthBearer - ci dice se vogliamo inserire il bearer di autenticazione nell'header dell'API. 
     * serve per le API di tipo guest che non accettano questo parametro, di default è settato a true
     * @return {Object}
     */
     formatRestOptions: function (MOP, params, insertAuthBearer = true) {

      const searchPath = params.searchPath; // Parametro obbligatorio.
      const querystring = params.querystring || '';
      const ajaxDefaultParams = MOP.config.getAjaxDefaultParamsV3(MOP);
      const options = { ...params.ajax.options };

      const headers = params.ajax.headers || {};
      const data = params.ajax.data || {};
  
      if (!MOP) {
        throw new ReferenceError('The "MOP" parameter is missing.');
      }
  
      if (!searchPath) {
        throw new ReferenceError('The "searchPath" parameter is missing.');
      }
  
      if (!options.method) {
        throw new ReferenceError('The "method" parameter is missing.');
      }
  
      // se non esiste il method, aggiungo di default il metodo GET
      // Modifico il method rendendolo tutto minuscolo
      !options.method && options.method === 'get';
      options.method = options.method.toLowerCase();

      const apiRequest = {
        url: `${MOP.Utilities.ApiV3Module.getRestApi_AjaxUrl(MOP, { searchPath: searchPath, querystring: querystring })}${$.param(ajaxDefaultParams)}`,

        data,
        headers,
  
        ...options,
        insertAuthBearer,

        // I seguenti sono tutti dati che servono a backbone per il settaggio della cache
        cache: MOP.config.getAjaxCache(searchPath),
        expires: MOP.config.getAjaxExpires(searchPath),
        cacheWithoutFilters: MOP.config.getAjaxWithoutFilters(searchPath, MOP.isAdminLoggedIn()),
        refreshCache: (options.nocache == 1 || params.nocache == 1 || params.nocache == 'client' || ajaxDefaultParams.nocache == 1)
      };
  
  
      return apiRequest;
    }
  };

  var ApiV2Module = {
    getRestApi_AjaxUrl: function (MOP, params) {
      return `${MOP.config.getAjaxUrl()}?${$.param(params)}`;
    },

    formatRestOptions: function (MOP, params) {

      if (!MOP) {
        throw new ReferenceError('The MOP parameter is mandatory');
      }

      const headers = params.ajax.headers || {};
      const data = params.ajax.data || {}
  
      // Le opzioni si trovano alcune volte in params.ajax.options ed altre in params.options
      let options = {}
      if (!MOP.Utilities.empty(params.ajax.options)) {
        options = { ...params.ajax.options };
      } else if (!MOP.Utilities.empty(params.options)) {
        options = { ...params.options };
      }
  
      // A volte il method potrebbe trovarsi in sotto la chiave "type" (lo inserisco nella chiave method), 
      // oppure non esistere (aggiungo il metodo GET di default).
      // poi lo trasformo in lowercase ed elimino type
      options.method = options.method || options.type;
      if(!options.method) options.method = 'get';
      options.type && delete (options.type);
      options.method = options.method.toLowerCase();
  
      // I dati della richiesta che nel caso di GET devono essere passati in querystring
      let ajaxGetData = {};
      if (options.method === 'get') {
        ajaxGetData = { ...params.ajax.data }
      }

      const ajaxDefaultParams = MOP.config.getAjaxDefaultParams(MOP, params.fetch_fn === 'start_new_mop');
      if(MOP.config.getAjaxServerCache(params.fetch_fn)) {
        ajaxDefaultParams.cacheMop = 1
      }
      if(options.method === 'post') {
        if (!MOP.Utilities.empty(params.ajax.data.sessionid)) {
          ajaxDefaultParams.sessionid = params.ajax.data.sessionid;
        }
        if (!MOP.Utilities.empty(params.ajax.data.ots_token)) {
          ajaxDefaultParams.ots_token = params.ajax.data.ots_token;
        }

        // le post v2 devono sempre essere forzate con header Content-Type: application/x-www-form-urlencoded
        headers['Content-Type'] = "application/x-www-form-urlencoded"
      }
  
      const apiRequest = {
        url: MOP.Utilities.ApiV2Module.getRestApi_AjaxUrl(MOP, {
          fn: params.fetch_fn,
          ...ajaxDefaultParams,
          ...ajaxGetData,
        }),
        data,
        headers,

        ...options,

        // I seguenti sono tutti dati che servono a backbone per il settaggio della cache
        cache:                MOP.config.getAjaxCache(params.fetch_fn),
        expires:              MOP.config.getAjaxExpires(params.fetch_fn),
        cacheWithoutFilters:  MOP.config.getAjaxWithoutFilters(params.fetch_fn, MOP.isAdminLoggedIn()),
        refreshCache:         (data.nocache == 1 || data.nocache == 'client' || ajaxDefaultParams.nocache == 1)
      }
  
      return apiRequest;
    }
  };

  function disconnectAlertInformation (MOP, retryCallback = null) {
    setTimeout(() => {
      function onConfirm(buttonIndex) {
      if (buttonIndex == 2) {
        setTimeout(() => retryCallback(), 0);
      }
    }

    let buttonsName = [TTPolyglot.t("OK")];
    let msg = TTPolyglot.t("User Offline Message");
    let title = TTPolyglot.t("User Offline Title");

    if ( retryCallback ) {
      buttonsName = [TTPolyglot.t("Cancel"), TTPolyglot.t("Retry")];
      msg = TTPolyglot.t('User Back Online Message');
      title = TTPolyglot.t('User Back Online Title');
    }

    navigator.notification.confirm(
        msg,  // message
        onConfirm,         // callback
        title,            // title
        buttonsName             // buttonName
    )
  },0)


  }


  function ajax(MOP, params, scope, insertAuthBearer) {
    var deferred = new $.Deferred();
    if (MOP.Utilities.isMobileApp() && !MOP.state.networkReachability){
      // se siamo in start_new_mop non chiamiamo l'alert perchè viene già fatto in main.js, dal momento che non sarebbe stato possibile richiamare lo start di main.js
      if(params.fetch_fn === 'logout'){
        MOP.resetSession();
      } else if (params.fetch_fn !== 'start_new_mop') {
        disconnectAlertInformation(MOP);
      }
      deferred.reject(MOP.config.constants.AJAX_OFFLINE_REJECT);
      return deferred;
    }
    var preventThrowException = params.preventThrowException || false;
    var ajaxDeferred = new $.Deferred();

    scope = scope || this;
    params.fetch_fn = scope.fetch_fn || params.fetch_fn;
    params.ajaxFunction = params.ajaxFunction || _ajaxRest;



    if (!MOP) {

      throw new ReferenceError('The MOP parameter is mandatory');
    }

    if (!params.searchPath && !params.fetch_fn) {
      throw new ReferenceError('You have to pass one parameter between searchPath and fetch_fn');
    }

    if (typeof params.ajaxFunction != 'function') {
      throw new TypeError('The ajaxFunction parameter has to be a function.');
    }

    //Eseguire solo su ios perchè su android non funziona TODO fixare anche per android
      if(!isMobileApp()) {
        // Se siamo offline non proseguiamo => Banner + deferred.reject
        if (!window.navigator.onLine) {
            var alert = {msg: TTPolyglot.t('Network Down'), showReloadPage: true};
            MOP.execute('MOP:alert:danger', alert);

            deferred.reject();
            return deferred;
        }
    }

    // ******* Loading options *******
    var loadingOptions = {
      enabled: false,
      replace_main_region: false,
      options: {}
    };

    Object.assign(loadingOptions, params.loading || {});

    loadingOptions.enabled && MOP.TM.blockLoading(loadingOptions.options, loadingOptions.replace_main_region);

    // ******** API Version **********
    // Il parametro "searchPath" viene utilizzato come
    // discriminante tra versione di api V3 e V2.
    var apiModuleName = 'ApiV' + (params.searchPath ? '3' : '2') + 'Module';
    var ajaxOptions = {};

    // In ajaxOptions mi aspetto di avere tutti i parametri che sono stati
    // passati alla funzione come argomento escluso le opzioni di loading.
    Object.assign(ajaxOptions, _.omit(params, 'loading')  || {});

    var restOptions = MOP.Utilities[apiModuleName].formatRestOptions(MOP, ajaxOptions, insertAuthBearer);

    restOptions.success = ajaxDeferred.resolve;
    restOptions.error = ajaxDeferred.reject;

    params.ajaxFunction.call(scope, restOptions);

    ajaxDeferred
      .always(function () {
        loadingOptions.enabled && MOP.TM.unblockLoading(loadingOptions.replace_main_region);
      })
      .done(deferred.resolve)
      .fail(function (jqXHR, textStatus) {

        MOP.Utilities.log(MOP, textStatus);

        var err_respone = jqXHR.responseJSON;

        // Non sempre la risposta è dentro jqXHR, ho notato che se viene usato il fetch di Backbone allora
        // la risposta di errore è dentro textStatus
        if (!jqXHR.responseJSON && textStatus.responseJSON) {
          err_respone = textStatus.responseJSON;
        }
        if(window.navigator.onLine) {
          deferred.reject(err_respone);
        }

      });

    return deferred;
  }

  /**
   * It exec the pollingFunction up to the stopFunction will be true or execution time exceed the maxExecutionTime.
   * @param {Object} params - literal object containing the Pollinger parameters
   * @param {Function} params.pollingFunction - function to be called each timeout ms
   * @param {Function} params.stopFunction - function to stop the polling
   * @param {Function} [params.timeout = 5000] - setInterval timeout
   * @param {Function} [params.maxExecutionTime = 3600e6] - max execution time of the polling cycle
   * @param {Boolean} [params.stopOnWindowsChange = false] - if true the polling execution will stop on hash change
   */
  function Pollinger(params) {
    if (typeof params.pollingFunction !== 'function') {
      throw new TypeError('The "pollingFunction" parameter has to be a function.');
    }

    if (typeof params.stopFunction !== 'function') {
      throw new TypeError('The "stopFunction" parameter has to be a function.');
    }

    var timeoutID;
    var _this = this;
    var deferred = new $.Deferred();

    var timeout = params.timeout || 5000; // 5 sec.
    var maxExecutionTime = params.maxExecutionTime || 12e4; // 2 min.
    var pollingFunction = params.pollingFunction;
    var stopFunction = params.stopFunction;
    var stopOnWindowsChange = params.stopOnWindowsChange || false;

    if (stopOnWindowsChange) {
      /*
        Spiegazione del perchè è dentro un timeout:
        https://stackoverflow.com/questions/33087166/understanding-execution-order-of-the-hashchange-event
      */
      setTimeout(function() {
        window.addEventListener('hashchange', _halt, false);
      }, 0);
    }

    function _caller(funcToRun, funcToStop) {
      $.when(funcToRun())
          .then(function (resp) {
              if (funcToStop(resp)) {
                  _halt();
                  deferred.resolve(resp);
              }
          })
          .fail(function (e) {
              deferred.reject(e);
          });
    }

    function _run(scope) {
      var proxiedpollingFunction = $.proxy(pollingFunction, scope || _this);

      timeoutID = setInterval(_caller, timeout, proxiedpollingFunction, stopFunction);

      setTimeout(function () {
          _halt();
          deferred.reject({ type: 'maxExecutionTime' });
      }, maxExecutionTime);

      return deferred;
    }

    function _halt() {
      clearInterval(timeoutID);
    }

    return {
        run: _run,
        halt: _halt
    };
  }

  var share = {

    buildDropBoxSaveFunction: function buildDropBoxSaveFunction(MOP, filesList) {

      if (!MOP) {

        throw new ReferenceError('The MOP parameter is mandatory');
      }
      if (!Dropbox || !Dropbox.isBrowserSupported() || !filesList) {
          return null;
      }
      var options = {
        files: filesList,
        // Success is called once all files have been successfully added to the user's
        // Dropbox, although they may not have synced to the user's devices yet.
        success: function() {
            // Indicate to the user that the files have been saved.
            // alert("Success! Files saved to your Dropbox.");
            var alert = {type: 'success', msg: TTPolyglot.t('Your request was processed successfully.')};

            MOP.execute('MOP:alert', alert);
        },

        // Progress is called periodically to update the application on the progress
        // of the user's downloads. The value passed to this callback is a float
        // between 0 and 1. The progress callback is guaranteed to be called at least
        // once with the value 1.
        progress: function(progress) {},

        // Cancel is called if the user presses the Cancel button or closes the Saver.
        cancel: function() {},

        // Error is called in the event of an unexpected response from the server
        // hosting the files, such as not being able to find a file. This callback is
        // also called if there is an error on Dropbox or if the user is over quota.
        error: function(errorMessage) {}
      };

      Dropbox.save(options);

    },

  downloadFile: function (MOP, url) {
    if (!MOP) {

      throw new ReferenceError('The MOP parameter is mandatory');
    }
    
    if (MOP.Utilities.isMobileApp()) {
      window.open(url, '_system');
    } else {
      window.open(url, '_blank');
    }
    
  },


  sendEmail: function(MOP, data){
    if (!MOP) {

      throw new ReferenceError('The MOP parameter is mandatory');
    }
    return MOP.ajax({
      fetch_fn: 'share_downloads',
      ajax: {
        data: data
      }
    });
  },

  shareWithEmail: function(MOP, data, _userid, loadingEnabled = true) {
    var params = {
      searchPath: 'downloads/' + data.id + '/shares',
      querystring: {userid: _userid},
      bypassCheckResponse: true,
      ajax: {
        options: {
          method: 'POST',
        },
        data: data
      },
      loading: {
        enabled: loadingEnabled
      }
    };

      return MOP.ajaxRest(params);
    }
  };

    /**
  * Check if the MOP is running in a mobile browser
  * @return {Boolean} - the result of checking
  */
  function isMobileNavigator() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
 /**
  * Check if the MOP is running in a mobile app
  * @return {Boolean} - the result of checking
  */
  function isMobileApp( no_fake ) {
	if( no_fake ){
		return window.MOP_globals.fromMobileApp && !(window.MOP_globals.isFakeMobileApp);
	}else{
		return window.MOP_globals.fromMobileApp;
	}
  }
 /**
  * Check if the MOP is running in an android device
  * @return {Boolean} - the result of checking
  */
  function isAndroidDevice () {
    return /Android/i.test(navigator.userAgent);
  }
    /**
  * Check if the MOP is running in an apple device
  * @return {Boolean} - the result of checking
  */
  function isAppleDevice() {
    if (isMobileApp() && window.device) {
        return window.device.platform === 'iOS';
    } else {
        return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    }
  }
    /**
  * Check if the MOP is running in an iPad
  * @return {Boolean} - the result of checking
  */
  function isIpad() {
   return /(iPad)/g.test(navigator.userAgent);
  }
    /**
  * Check if val is undefined
  * @param {Object} val - the variable to check
  * @return {Boolean} - the result of checking
  */
  function isUndefined(val) {
      return typeof val === 'undefined';
  }
    /**
  * Check if the running browser is Opera
  * @return {Boolean} - the result of checking
  */
  function isOpera() {
    // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
    return !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  }
    /**
  * Check if the running browser is Firefox
  * @return {Boolean} - the result of checking
  */
  function isFirefox() {
    // Firefox 1.0+
    return typeof InstallTrigger !== 'undefined';
  }
    /**
  * Check if the running browser is Safari
  * @return {Boolean} - the result of checking
  */
  function isSafari() {
    // At least Safari 3+: "[object HTMLElementConstructor]"
    return Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
  }
    /**
  * Check if the running browser is Chrome
  * @return {Boolean} - the result of checking
  */
  function isChrome() {
    // Chrome 1+
    return !!window.chrome && !isOpera();
  }
    /**
  * Returns the version of Internet Explorer
  * @return {Number} - the version
  */
  function getInternetExplorerVersion() {
    var rv = -1;

    if (navigator.appName == 'Microsoft Internet Explorer') {
        var ua = navigator.userAgent;
        var re  = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
        if (re.exec(ua) != null)
            rv = parseFloat( RegExp.$1 );
    }
    else if (navigator.appName == 'Netscape') {
        var ua = navigator.userAgent;
        var re  = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
        if (re.exec(ua) != null)
            rv = parseFloat( RegExp.$1 );
    }

    return rv;
  }
    /**
  * Check if the running browser is Internet Explorer, if true return the version
  * @return {Boolean | Number} - the result of checking | version of the browser
  */
  function isIE() {
    var ieVersion = getInternetExplorerVersion();

    return ieVersion != -1 ? ieVersion : false;
  }
    /**
  * Returns value rounded to specified precision
  * @param {Number} value - the value to rounds
  * @param {Number} precision - the precision needed (> 0)
  * @param {String} mode - the modality of rounding
  * @return {Number} - the result of rounds
  */
  function round(value, precision, mode) {
    if (typeof value !== 'number') {
      throw new TypeError('The parameter has to be a number');
    }
    if (typeof precision !== 'number') {
      throw new TypeError('The parameter has to be a number');
    }
    if (!empty(mode) && typeof mode !== 'string') {
      throw new TypeError('The parameter has to be a string');
    }
    if(precision < 0) {
      throw new RangeError('The parameter has to be >= 0');
    }

    var m, f, isHalf, sgn; // helper variables

    precision |= 0; // making sure precision is integer
    m = Math.pow(10, precision);
    value *= m;
    sgn = (value > 0) | -(value < 0); // sign of the number
    isHalf = value % 1 === 0.5 * sgn;
    f = Math.floor(value);
    if (isHalf) {
      switch (mode) {
        case 'PHP_ROUND_HALF_DOWN':
            value = f + (sgn < 0); // rounds .5 toward zero
            break;
        case 'PHP_ROUND_HALF_EVEN':
            value = f + (f % 2 * sgn); // rouds .5 towards the next even integer
            break;
        case 'PHP_ROUND_HALF_ODD':
            value = f + !(f % 2); // rounds .5 towards the next odd integer
            break;
        default:
            value = f + (sgn > 0); // rounds .5 away from zero
      }
    }
    return (isHalf ? value : Math.round(value)) / m;
  }
    /**
   * It format a distance expressed in kilometer to meter or kilometer
   *
   * @param {Number} distance - meter to formats in meter or kilometer
   * @return {String | undefined} - the formatted distance | undefined if the distance is 0
   */
  function formatDistanceToPrint( distance ){

    if (distance) {
    	distance = Number(distance);
        if( distance < 1 ){
            var format_distance = ((round(distance, 3)) * 1000) + ' m ';
        }else{
            var format_distance = round(distance, 1) + ' km ';
        }

        return format_distance;
    }
    else {
        return undefined;
    }
 }
    /**
   * It format a time duration expressed in seconds to HH:SS
   * @param {Number} seconds - seconds to formats in HH:SS
   * @return {String}
   */
  function formatTimeFromSeconds(seconds) {

    var d = Number(seconds);
    var hours = Math.floor(d / 3600);
    var minutes = Math.floor(d % 3600 / 60);

    return (hours ? hours + 'h' : '') + ' ' + (minutes ? minutes + 'm' : '');
  }

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);

    return (seconds == 60 ? (minutes+1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
  }

    /*
  * Return the timestamp of today
  * @return {Number}
  */
  function getTodayTimestamp() {
    var d = new Date();
    return (d - (d % 86400000))/1000;
  }
    /*
  * Return the timestamp
  * @return {Number}
  */
  function getTimestamp () {
    return new Date().getTime() / 1000;
  };

  /*
   * Return the hh:mm time format from unix time
   * @param {Number} unix_timestamp - unix_timestamp
   * @return {String} the time formatted
   */
  function unixTimeToHourMinute (unix_timestamp) {
      //Lavoriamo in UNIX TIMESTAMP, che è in SECONDI, invece le date in Javascript sono in millesecondi, quindi *1000
      var date = new Date(unix_timestamp*1000);
      // Hours part from the timestamp
      var hours = date.getHours();
      // Minutes part from the timestamp
      var minutes = "0" + date.getMinutes();
      var formattedTime = hours + ':' + minutes.substr(-2);

      return formattedTime;
  }

  function unixToDayAndDayWeek (unix) {

    var date = new Date(unix*1000);
    var day = date.getDate();

    return `${day} - ${getWeekDay(date)}`;
  }

 /*
  * Return the public Url
  * @return {String}
  */
  function getPublicURL(MOP) {
    return MOP.getDbName().replace(/_/g, '');
  }
    /*
  * Check if the environment is set to production
  * @param {Object} MOP - a MOP instance
  * @return {Boolean}
  */
  function weAreOnProduction(MOP) {
    if (!MOP) {

      throw new ReferenceError('The MOP parameter is mandatory');
    }
    return (MOP.environment === 'production');
  }
    /*
  * Check if the environment is set to development
  * @param {Object} MOP - a MOP instance
  * @return {Boolean}
  */
  function weAreOnDevelopment(MOP) {
    if (!MOP) {

      throw new ReferenceError('The MOP parameter is mandatory');
    }
    return (MOP.environment === 'developement');
  }
    /*
  * Print console.log only if we re on development
  * @param {Object} MOP - a MOP instance
  * @param {String} msg - the msg to print
  * @return
  */
  function log(MOP, msg){
    if (!MOP) {

      throw new ReferenceError('The MOP parameter is mandatory');
    }
    if (weAreOnDevelopment(MOP)) {
      console.log(msg);
      console.trace && console.trace();
    }
  }

    /*
  * Encode querystring
  * @param {String} string - the string to encode
  * @return {String} - the encoded querystring
  */
  function encodeQuery(string) {
    if (!empty(string) && typeof string !== 'string') {
      throw new TypeError('The parameter has to be a string');
    }

    // I nomi che contengono il simbolo % generano una querystring invalida.
    string = string.replace(/%/g, "{PERCENT}");
    // I nomi che contengono il simbolo ' non fanno rispettare in firefox l'option trigger:false del metodo navigate di backbone
    string = string.replace(/'/g, "{SINGLE_QUOTE}");
    var encodedString = encodeURIComponent(string);     // URI encoding
    //FIXME[GIO]
    //    encodedString = window.btoa(encodedString);         // BASE64 encoding
    //    encodedString = encodedString.replace(/\//g, "[SLASH]");  // Replacing '/' character not to break the route
    return encodedString;
  }
    /*
  * Decode querystring with base64
  * @param {String} encodedString - the string to decode
  * @return {String} - the encoded querystring
  */
  function decodeQuery(encodedString) {
    if (!empty(encodedString) && typeof encodedString !== 'string') {
      throw new TypeError('The parameter has to be a String');
    }

    var string = decodeURIComponent(encodedString);     // URI decoding
    // I nomi che contengono il simbolo % generano una querystring invalida.
    string = string.replace(/{PERCENT}/g, "%");
    // I nomi che contengono il simbolo ' non fanno rispettare in firefox l'option trigger:false del metodo navigate di backbone
    string = string.replace(/{SINGLE_QUOTE}/g, "'");
    //FIXME[GIO]
    //    string = encodedString.replace(/[SLASH]/g, "/");      // Restoring '/' character
    //    string = window.atob( string );               // BASE64 decoding
    return string;
  }
    /*
  * Return the options about forgotten password
  * @param {Object} resp - the response object where extrapolate the options
  * @return {Object} - the options
  */
  function extractForgotPasswordOptions(resp) {
    if (typeof resp !== 'object') {
      throw new TypeError('The parameter has to be an Object');
    }

    var options = {
      status:   "",
      media:    "",
      attempt:  "",
      translate1: "",
      translate2: ""
    };

    //status
    if(resp.duplicates) {
      options.status = "duplicates";
    } else if (resp.register) {
      options.status = "register";
    } else if(resp.waiting) {
      options.status = "waiting";
    }

    //media
    if(resp.email) {
      options.media = "email";
    }else if (resp.sms) {
      options.media = "sms";
    }

    //attempt
    if(resp.attemptNum) {
      options.attempt = resp.attemptNum;
    }

    //translate1
    if(resp.translate1) {
      options.translate1 = resp.translate1;
    }

    //translate2
    if(resp.translate2) {
      options.translate2 = resp.translate2;
    }

    return options;
  }
    /*
  * Convert the date object in a readable human data
  * @param {Object} [MOP] - a MOP instance
  * @param {Object} date - the date object
  * @param {String} [date_format] - the format of the date
  * @return {String} - the human readable data
  */
  function dateObjToHumanDate(MOP, date, date_format, remove_year) {

    var date_format = date_format || MOP.config.getInstanceConfig('dateFormat');
    var day = date.getDate();
    var month = date.getMonth();
    month = month + 1; //i mesi partono da 0, per questo c'è il +1
    var year = date.getFullYear();

    day = (day < 10) ? '0' + day : day;
    month = (month < 10) ? '0' + month : month;

    switch (date_format) {
      case "us":
        return (month + "/" + day + (remove_year ? "" : "/" + year));
      case "jp":
        return ((remove_year ? "" : year + "/") + month + "/" + day);
      case "eu":
      default:
        return (day + "/" + month + (remove_year ? "" : "/" + year));
    }
  }
  
  function dateObjToHumanTime(MOP, date, time_format) {

    var timeFormat = time_format || MOP.config.getInstanceConfig('timeFormat');
    var h = date.getHours();
    var m = date.getMinutes();
    
    var x = "";
    if (parseInt(timeFormat) != 24) {
      x = h >= 12 ? ' pm' : ' am';
      h = h % 12;
      h = h ? h : 12;
    }
    
    m = m < 10 ? '0'+m : m;
    
    var time = h + ':' + m + x;
    
    return time;
  }

  function dateObjToHumanDateTime (MOP, date, date_format, time_format) {
    var dateString = dateObjToHumanDate(MOP, date, date_format);
    var timeString = dateObjToHumanTime(MOP, date, time_format);

    return dateString + " " + timeString;
  }

    /*
  * Convert the date in a Data Object
  * @param {Object} [MOP] - a MOP instance
  * @param {String} date - the date in human readable format
  * @param {String} time - thetime in human readable format
  * @param {String} [date_format] - the format of the date
  * @return {Date} - the Date Object
  */
  function humanDateToDateObj(MOP, date, time, date_format) {
    if (!empty(date) && typeof date !== 'string') {
      throw new TypeError('The parameter has to be a String');
    }
    if (!empty(time) && typeof time !== 'string') {
      throw new TypeError('The parameter has to be a String');
    }
    if (!empty(date_format) && typeof date_format !== 'string') {
      throw new TypeError('The parameter has to be a String');
    }

    var date_format = date_format || MOP.config.getInstanceConfig('dateFormat');
    var resDate = date.split("/");
    if (time) {
      if (time.indexOf('am') !== -1 || time.indexOf('pm') !== -1) {
        var pm = time.indexOf('pm') !== -1;
        time = time.replace(pm ? 'pm' : 'am', '');
      }

      var resTime = time.split(":");
      // FD 87641
      // gestita casistica 12:xxPM per non aggiungere 12 ore nel parsing format 12h to 24h
      resTime[0] = pm && parseInt(resTime[0], 10) < 12 ? parseInt(resTime[0], 10) + 12 : resTime[0];
    } else {
      var resTime = [0,0];
    }

    switch (date_format){
      case "us":
        var year = resDate[2];
        var month = (resDate[0]-1);//resDate[-]-1 //i mesi partono da 0, per questo c'è il -1 Simone
        var day = resDate[1];
        var hours = resTime[0];
        var minutes = resTime[1];
        break;
      case "jp":
        var year = resDate[0];
        var month = (resDate[1]-1);//resDate[-]-1 //i mesi partono da 0, per questo c'è il -1 Simone
        var day = resDate[2];
        var hours = resTime[0];
        var minutes = resTime[1];
        break;
      case "eu":
      default:
        var year = resDate[2];
        var month = (resDate[1]-1);//resDate[-]-1 //i mesi partono da 0, per questo c'è il -1 Simone
        var day = resDate[0];
        var hours = resTime[0];
        var minutes = resTime[1];
    }

    return new Date(year, month, day, hours, minutes);
  };

/*
 * Convert the time in minutes
 * @param {String} time - a time with format HH:MM
 * @return {Number} - the number of minutes
 */
  function convertTimeInMinutes(time) {
      var hours_minutes = time.split(':');
      if(hours_minutes.length != 2) {
          throw new ReferenceError('The format of input time is incorrect. (Must be HH:MM');
      }
      if (typeof time !== 'string') {
          throw new TypeError('The parameter has to be a String');
      }
      return parseInt(hours_minutes[0])*60 + parseInt(hours_minutes[1]);
  };

  /*
  }
    /*
  * Convert the date in a Data Object using the current Date
  * @param {Object} MOP - a MOP instance
  * @return {Date} - the Date Object
  */
  function humanDateToDateObjDate(MOP) {
    if (!MOP) {

      throw new ReferenceError('The MOP parameter is mandatory');
    }
    return humanDateToDateObj.apply(MOP, Array.prototype.slice.call(arguments)).getDate();
  }
    /*
  * Convert the date in a Data Object using the current Year
  * @param {Object} MOP - a MOP instance
  * @return {Date} - the Date Object
  */
  function humanDateToDateObjYear(MOP) {
    if (!MOP) {

      throw new ReferenceError('The MOP parameter is mandatory');
    }
    return humanDateToDateObj.apply(MOP, Array.prototype.slice.call(arguments)).getFullYear();
  }

    /**
   * Return the given url without the query string parameter
   * @param {String} url
   * @param {String} parameter
   * @return {String}
   */
  function removeParameterFromUrl(url, parameter) {
    return url
      .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
      .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
    }
    function getDeviceEventsFromCalendar(MOP,startDate,endDate) {

    var cal = window.plugins.calendar;

    if (empty(startDate) && empty(endDate)) {
      startDate = new Date();
      endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    }

    var allEvents = [];

    var deferredMaster = new $.Deferred();
    
    var listCalendarsSuccess = function(calendars) {

      if (calendars.length > 0) {

        var cal_length = calendars.length;
        _.each(calendars, function(cal) {

          var onSuccess = function(events) {
            allEvents = allEvents.concat(events);
            cal_length--;
            if (cal_length == 0) {
              if (deferredMaster.state() == "pending") {
                deferredMaster.resolve(allEvents);
              }
            }
          };
          var onError = function(events) {
            cal_length--;
            if (cal_length == 0) {
              if (deferredMaster.state() == "pending") {
                deferredMaster.resolve(allEvents);
              }
            }
          };

          var calOptions = window.plugins.calendar.getCalendarOptions();
          calOptions.id = cal.id; //Utilizziamo l'ID che è supportao dalla libreria per iOS e Android
          calOptions.calendarName = cal.name;
          window.plugins.calendar.findEventWithOptions("","","",startDate,endDate,calOptions,onSuccess,onError);
        });
      } else {
        
        if (deferredMaster.state() == "pending") {
          deferredMaster.resolve(allEvents);
        }
      }

    };
    var listCalendarsError = function(error) {
      deferredMaster.reject(error);
    };

    window.plugins.calendar.listCalendars(listCalendarsSuccess,listCalendarsError);
    
    return deferredMaster.promise();
  }
    function isExternalPayment(MOP, payment_method, goToPaymentCheck) {

    if (!MOP) {
      throw new ReferenceError('The MOP parameter is mandatory');
    }

    // goToPaymentCheck indica che stiamo andando alla pagina del pagamento, se siamo in app tutti i provider
    // esterni escono dall'app, per carta si invece abbiamo gestito come inAppBrowser quindi lo consideriamo interno
    if (goToPaymentCheck && payment_method == MOP.constants.PAYMENT_METHOD_CARTASI) {
      return false;
    }
    
    if (payment_method == MOP.constants.PAYMENT_METHOD_PAYPAL || payment_method == MOP.constants.PAYMENT_METHOD_CARTASI 
      || payment_method == MOP.constants.PAYMENT_METHOD_REDSYS || payment_method == MOP.constants.PAYMENT_METHOD_INGENICO 
      || payment_method == MOP.constants.PAYMENT_METHOD_CONEKTA || payment_method == MOP.constants.PAYMENT_METHOD_CONEKTA_HOSTED 
      || payment_method === MOP.constants.PAYMENT_METHOD_TRANSBANK
    ) {
      return true;
    }

    return false;
  }
  /*
  Piero
  Questa funzione permette di aprire un url in un nuovo tab passando in post dei parametri
  */
  function openUrlInNewTabWithPostParameters(url, params) {
    
    var f = $("<form target='_blank' method='POST' style='display:none;'></form>").attr({
        action: url
    }).appendTo(document.body);
    
    for (var i in params) {
      if (params.hasOwnProperty(i)) {
        $('<input type="hidden" />').attr({
          name: i,
          value: params[i]
        }).appendTo(f);
      }
    }
    
    f.submit();
    f.remove();
  }
  
  function objectToQueryString(obj) {
    var str = [];
    for(var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }
  
  function orderArrayByProperty(property) {
      var sortOrder = 1;
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
      }
  }

  function getAgeFromDate (MOP, dateString) {
      var dateParts = dateString.split('/');
      var today = new Date();
      var birthDate = humanDateToDateObj(MOP, dateString);

      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }

      return age;
  }

    function getParameterByName (name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    function openRightMap (MOP,latitude,longitude, address) {

        if (MOP.Utilities.isMobileApp()) {

            var deferred = new $.Deferred();
            var index = 0;
            var that = this;

            var ios_schema_googlemap = "";
            var android_schema_googlemap = "";

            if (!MOP.Utilities.empty(address)) {
              ios_schema_googlemap = "comgooglemaps://?q="+encodeURI(address)+"&center="+latitude+","+longitude;
              android_schema_googlemap = "geo:"+latitude+","+longitude+"?q="+encodeURI(address);
            } else {
              ios_schema_googlemap = "comgooglemaps://?q="+latitude+","+longitude;
              android_schema_googlemap = "geo:0,0?q="+latitude+","+longitude;
            }

            var available = [];
            var apps = [
                {
                    schema: MOP.Utilities.isAppleDevice() ? "comgooglemaps://" : "com.google.android.apps.maps",
                    app: "Google Maps",
                    schemaUrl: MOP.Utilities.isAppleDevice() ? ios_schema_googlemap : android_schema_googlemap
                },
                {
                    schema: MOP.Utilities.isAppleDevice() ? "waze://" : "com.waze",
                    app: "Waze",
                    schemaUrl: "https://waze.com/ul?ll="+latitude+","+longitude
                }
            ];

            apps.forEach(function(element) {
                appAvailability.check(
                    element.schema, // URI Scheme
                    function() {  // Success callback
                        available.push(element);
                        index = index + 1;
                        if (index == apps.length) {
                            deferred.resolve(available);
                        }
                    },
                    function() {  // Error callback
                        index = index + 1;
                        if (index == apps.length) {
                            deferred.resolve(available);
                        }
                    }
                );

            });

            deferred.done(function (founded) {

                if (MOP.Utilities.isAppleDevice()) {
                    founded.push({
                        schema: "maps",
                        app: TTPolyglot.t("App Maps Dialog Maps",[],true),
                        schemaUrl: 'maps://?q='+latitude+","+longitude
                    });
                }

                function onConfirm(buttonIndex) {
                    if (buttonIndex != buttons.length) {
                        window.open(founded[buttonIndex-1].schemaUrl, '_system');
                    }
                }

                var buttons = founded.map(function(value) {
                    return value.app;
                });
                buttons.push(TTPolyglot.t("App Maps Dialog Cancel",[],true));

                navigator.notification.confirm(
                    TTPolyglot.t("App Maps Dialog Description",[],true), // message
                    onConfirm,            // callback to invoke with index of button pressed
                    '',           // title
                    buttons     // buttonLabels
                );

            });

        } else {
            //Aprire con l'app nativa del sistema operativo
            if (MOP.Utilities.isAppleDevice()) {
                //Per iOS usiamo le mappe dell'apple perchè siamo sicuro che ci sono
                window.open('maps://?q='+latitude+","+longitude, '_system');
            } else {
                //Per tutti gli altri aprire google map, che se non c'è l'app viene aperto via web
                window.open('https://maps.google.com/?q='+latitude+","+longitude, '_system');
            }
        }

    }

    function createHiddenFormInput(name, value) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', name);
      input.setAttribute('value', value);
      return input;
    }

    function createDeferredForm(MOP, deferredObject) {
      
      const form = document.createElement('form');
      form.setAttribute('method', "post");
      form.setAttribute('type', 'hidden');
      form.setAttribute('id', "deferredForm");
      const redirectUrl = deferredObject.mopBookabilityOptions.deferredTarget;
      
      form.setAttribute('action', MOP.Utilities.externalLinkProtocolValidator(MOP, redirectUrl));

      const keys = ["insuranceid", "activityid", "activityTitle", "typologyTitle"];

      keys.map(key => {
        form.appendChild(MOP.Utilities.createHiddenFormInput(key, deferredObject[key]));
      });

      form.appendChild(MOP.Utilities.createHiddenFormInput('dbName', MOP.getDbName()));
      form.appendChild(MOP.Utilities.createHiddenFormInput('lang', MOP.config.get("lang")));

      if (!MOP.Utilities.empty(deferredObject.areaTitle)) {
        form.appendChild(MOP.Utilities.createHiddenFormInput('areaTitle', deferredObject.areaTitle));
      }

      if (!MOP.Utilities.empty(deferredObject.areaAddress)) {
        form.appendChild(MOP.Utilities.createHiddenFormInput('areaAddress', deferredObject.areaAddress));
      }

      if(!MOP.Utilities.empty(deferredObject.areaRegion)) {
        // Regione del criterio delle aree quando l'utente seleziona l'opzione "cerca in tutte le aree di questa regione"
        // La chiamiamo areaRegion per distinguerla dalla region dell'indirizzo dell'utente
        // Richiesta in questo ticket: https://tuotempo.freshdesk.com/a/tickets/102896
        form.appendChild(MOP.Utilities.createHiddenFormInput('areaRegion', deferredObject.areaRegion));
      }

      if(!MOP.Utilities.empty(deferredObject.areaProvince)) {
        // Provincia del criterio delle aree quando l'utente seleziona l'opzione "cerca in tutte le aree di questa provincia"
        // La chiamiamo areaProvince per distinguerla dalla provincia dell'indirizzo dell'utente
        // Richiesta in questo ticket: https://tuotempo.freshdesk.com/a/tickets/102896
        form.appendChild(MOP.Utilities.createHiddenFormInput('areaProvince', deferredObject.areaProvince));
      }

      if (!MOP.Utilities.empty(deferredObject.activityTags)) {
        form.appendChild(MOP.Utilities.createHiddenFormInput('activityTags', deferredObject.activityTags));
      }

      if (MOP.Utilities.isMobileApp() && !MOP.Utilities.empty(window.MOP_globals.customerConfig.schemaUrl)) {
        form.appendChild(MOP.Utilities.createHiddenFormInput('from', 'app'));
        form.appendChild(MOP.Utilities.createHiddenFormInput('schemaUrl', window.MOP_globals.customerConfig.schemaUrl));
      } else {
        form.appendChild(MOP.Utilities.createHiddenFormInput('from', 'web'));
      }

      if (MOP.isLoggedIn()){
        const userData = MOP.getMainLoggedUserData();

        Object.keys(userData).map(key => {
          form.appendChild(MOP.Utilities.createHiddenFormInput(key, userData[key]));
        });
      }

      document.body.append(form);

      return form;
    }

    function handleFormRedirection(MOP, form) {
      if (!MOP.Utilities.isMobileApp()) {
        // FD92854 
        // controllo per evitare l'errore dell'apertura nuova tab in Safari Mobile
        const isSafariMobile = MOP.config.isMobile() && isSafariLayoutLib();
        if (MOP.Utilities.empty(MOP.config.getInstanceConfig('customDeferredReservationTarget')) && !isSafariMobile) {
          form.setAttribute("target", "_blank");
          form.submit();
          MOP.changePage('search', null);
        } else {

          if (MOP.config.isInIFrame()) {
            MOP.config.isMobile() && form.setAttribute("target", "_top");
            MOP.sendMessageToParentFrame({
              fn: 'evalFunction',
              value: 'var iframe = document.getElementById("mop_iframe");iframe.setAttribute("scrolling", "yes");'
            });
          }
          form.submit();
        }
      } else {
        // ref https://stackoverflow.com/a/41185803

        // Il campo innerhtml della form contiene solo l'input 
        // con gli attributi da inviare
        var pageContent = `<html><head></head><body><form id="${form.id}" type="hidden" action="${form.action}" method="post">${form.innerHTML}</form>` +
          `</form> <script type="text/javascript">document.getElementById("${form.id}").submit();</script></body></html>`;
        var pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);

        var options = "location=no,hidespinner=yes";
        var ref = cordova.InAppBrowser.open(pageContentUrl, '_blank', options);

        ref.addEventListener('exit', function () {
          return MOP.changePage('home', null);
        });

      }

      form.remove();
    }

    function handleSSOFormRedirection(MOP, form) {
      if (!MOP.Utilities.isMobileApp()) {

        // Se sono in iframe vado a fare redirect sulla pagina principale, non dentro l'iframe
        if (MOP.config.isInIFrame()) {
          MOP.config.isMobile() && form.setAttribute("target", "_top");
          MOP.sendMessageToParentFrame({
            fn: 'evalFunction',
            value: 'var iframe = document.getElementById("mop_iframe");iframe.setAttribute("scrolling", "yes");'
          });
        } else {
          // Altrimenti faccio redirect nella stessa pagina
          form.setAttribute("target", "_self");
        }

        form.submit();
        
      } else {
        // ref https://stackoverflow.com/a/41185803

        // Il campo innerhtml della form contiene solo l'input 
        // con gli attributi da inviare
        var pageContent = `<html><head></head><body><form id="${form.id}" type="hidden" action="${form.action}" method="post">${form.innerHTML}</form>` +
          `</form> <script type="text/javascript">document.getElementById("${form.id}").submit();</script></body></html>`;
        var pageContentUrl = 'data:text/html;base64,' + btoa(pageContent);

        var options = "location=no,hidespinner=yes";
        var ref = cordova.InAppBrowser.open(pageContentUrl, '_blank', options);

        ref.addEventListener('exit', function () {
          return MOP.changePage('home', null);
        });

      }

      form.remove();
      

    }

    //L'idtype=1 ossia l'idnumber è un campo particolare che varia da nazione a nazione, es in italia abbiamo
    // il Codice Fiscale quindi se in pagina viene selezionata la lingua spagnola questo viene tradotto come DNI
    // che è l'equivalente spagnolo, ma l'istanza essendo italiana lo valida come codice fiscale, quindi per questo caso
    // andiamo a gestire alcune lingue in maniera hardcoded
    function translateIDType (MOP, idtype) {
        var idtypes = MOP.config.getInstanceConfig('idtypes');

        var id_type_translate = "";

        if (!idtype) {
          id_type_translate = TTPolyglot.t("Document");
        } else if (
            parseInt(idtype) == 1 && 
            (
              MOP.config.getInstanceConfig('defaultPrefix') == '+39' || 
              MOP.config.getInstanceConfig('defaultPrefix') == '+34' ||
              MOP.config.getInstanceConfig('defaultPrefix') == '+55' ||
              MOP.config.getInstanceConfig('defaultPrefix') == '+52' ||
              MOP.config.getInstanceConfig('defaultPrefix') == '+56'
            )
          ) {
            if (MOP.config.getInstanceConfig('defaultPrefix') == '+39')  {
                id_type_translate = TTPolyglot.t("Idnumber_it")
            } else if (MOP.config.getInstanceConfig('defaultPrefix') == '+34') {
                id_type_translate = TTPolyglot.t("Idnumber_es")
            } else if (MOP.config.getInstanceConfig('defaultPrefix') == '+55') {
              id_type_translate = TTPolyglot.t("Idnumber_br")
            } else if (MOP.config.getInstanceConfig('defaultPrefix') == '+52') {
              id_type_translate = TTPolyglot.t("Idnumber_mx")
            } else if (MOP.config.getInstanceConfig('defaultPrefix') == '+56') {
              id_type_translate = TTPolyglot.t("Idnumber_cl")
            }
        } else {
            id_type_translate = TTPolyglot.t(idtypes[idtype]);
        }

        return id_type_translate;
    }

    function makeRandomID(_length) {

        var length = _length ? _length : 4;

        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < length; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    function humanDateLongFormat (MOP, start_date_day_abbr_week, start_date, start_date_month, start_date_year) {

      var date_format = MOP.config.getInstanceConfig('dateFormat');
      var str_date = '';

      switch (date_format){
        case "us":
            str_date = start_date_day_abbr_week + ", " + start_date_month + " " + start_date + ", " + start_date_year;
            break;
        case "jp":
            str_date = start_date_year + ", " + start_date_month + " " + start_date + ", " + start_date_day_abbr_week;
            break;
        case "eu":
        default:
            str_date = start_date_day_abbr_week + " " + start_date + " " + start_date_month + " " + start_date_year;
            break;
      }

      return str_date;
    }

    function getMonthsArray (MOP) {
        return [
            TTPolyglot.t('January'),
            TTPolyglot.t('February'),
            TTPolyglot.t('March'),
            TTPolyglot.t('April'),
            TTPolyglot.t('May'),
            TTPolyglot.t('June'),
            TTPolyglot.t('July'),
            TTPolyglot.t('August'),
            TTPolyglot.t('September'),
            TTPolyglot.t('October'),
            TTPolyglot.t('November'),
            TTPolyglot.t('December')
        ];
    }

    function getWeekDaysArray (MOP) {
        return [
            TTPolyglot.t('Monday'),
            TTPolyglot.t('Tuesday'),
            TTPolyglot.t('Wednesday'),
            TTPolyglot.t('Thursday'),
            TTPolyglot.t('Friday'),
            TTPolyglot.t('Saturday'),
            TTPolyglot.t('Sunday')
        ];
    }

    function getWeekDaysAbbreviatedArray (MOP) {
      return [
          TTPolyglot.t('MOP Calendar Abbreviation Monday'),
          TTPolyglot.t('MOP Calendar Abbreviation Tuesday'),
          TTPolyglot.t('MOP Calendar Abbreviation Wednesday'),
          TTPolyglot.t('MOP Calendar Abbreviation Thursday'),
          TTPolyglot.t('MOP Calendar Abbreviation Friday'),
          TTPolyglot.t('MOP Calendar Abbreviation Saturday'),
          TTPolyglot.t('MOP Calendar Abbreviation Sunday')
      ];
    }

    function getWeekDaysArrayFromSunday (MOP) {
        return [
          TTPolyglot.t('Sunday'),
          TTPolyglot.t('Monday'),
          TTPolyglot.t('Tuesday'),
          TTPolyglot.t('Wednesday'),
          TTPolyglot.t('Thursday'),
          TTPolyglot.t('Friday'),
          TTPolyglot.t('Saturday')
        ];
    }

    function createCancelDeferredForm(MOP, reservation) {

      const form = document.createElement('form');
      form.setAttribute('method', "post");
      form.setAttribute('type', 'hidden');
      form.setAttribute('id', "deferredForm");
      form.setAttribute('action', MOP.Utilities.externalLinkProtocolValidator(MOP, reservation.deferred_cancel_url));
      
      const keys = 
      [
        "activityid", 
        "activityTitle", 
        "areaTitle", 
        "insuranceid", 
        "typologyTitle", 
        "fname",
        "lname",
        "phone"
      ];

      keys.map(key => {
        form.appendChild(MOP.Utilities.createHiddenFormInput(key, reservation[key]));
      });

      form.appendChild(MOP.Utilities.createHiddenFormInput('request_type', "cancel_request"));
      form.appendChild(MOP.Utilities.createHiddenFormInput('resid', reservation.id));
      form.appendChild(MOP.Utilities.createHiddenFormInput('areaAddress', reservation.address));
      form.appendChild(MOP.Utilities.createHiddenFormInput('dbName', MOP.getDbName()));
      form.appendChild(MOP.Utilities.createHiddenFormInput('lang', MOP.config.get("lang")));
      form.appendChild(MOP.Utilities.createHiddenFormInput('user_name', `${reservation.fname} ${reservation.lname}`)); 

      if (!MOP.Utilities.empty(reservation.lname_2)) {
        form.appendChild(MOP.Utilities.createHiddenFormInput('lname_2', reservation.lname_2)); 
      }

      if (MOP.Utilities.isMobileApp() && !MOP.Utilities.empty(window.MOP_globals.customerConfig.schemaUrl)) {
        form.appendChild(MOP.Utilities.createHiddenFormInput('from', 'app'));
        form.appendChild(MOP.Utilities.createHiddenFormInput('schemaUrl', window.MOP_globals.customerConfig.schemaUrl));
      } else {
        form.appendChild(MOP.Utilities.createHiddenFormInput('from', 'web'));
      }

      document.body.append(form);

      return form;
    }

    function hexToRGB(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }
    
    function sentryCaptureMessage (MOP, msg) {
      
      if (typeof Sentry !== "undefined") {

        // Per il momento non logghiamo più eventi di GoogleMap, lo faccio in questo punti qui, perchè nel PHP dove includiamo Sentry
        // e c'è la funzione beforeSend  non abbiamo le costanti del MOP
        if (msg.indexOf(MOP.config.constants.SENTRY_INFO_GOOGLEMAP) != -1) return;

        Sentry.captureMessage(msg);

      }
    }

    function sentryAddBreadcrumb (MOP, data) {

      if (typeof Sentry !== "undefined") {

        Sentry.addBreadcrumb(data);
      }
    }
    
    function sendAppEvents (MOP, data) {
      var params = {
        searchPath: 'appevents',
        bypassCheckResponse: true,
        ajax: {
          options: {
            method: 'POST',
          },
          data: data
        },
        loading: {
          enabled: false
        }
      };

        MOP.ajaxRest(params);
      }
      
    function createSchemaUrl (MOP, schemaUrl, queryStringParams) {
      
      var url = schemaUrl + "://";
      
      if (queryStringParams) {
        url = url + "?" + queryStringParams;
      }

      return url;
    }

    function handleSchemaUrl (MOP, url) {

      var schemaUrlProtocol = url.substring(0, url.indexOf("://"));

      var jump = true;
      if (window.MOP_globals.customerConfig.schemaUrl) {
        if (schemaUrlProtocol == window.MOP_globals.customerConfig.schemaUrl) {
          jump = false;
        }
      } else {
        // Se entro qui allora l'app era chiusa perchè customerConfig è vuoto, in questo caso lo 
        // accetto perchè sicuramente se sono arrivato in handleOpenURL da app chiusa è perchè ho 
        // chiamato uno schemaUrl
        jump = false
      }
      
      if (!jump) {
          
        MOP.queryStringSchemaUrl = querystringToObject(url.slice(url.indexOf('?') + 1));
        
        if ((!MOP.Utilities.empty(MOP.queryStringSchemaUrl["sessionid"]) || !MOP.Utilities.empty(MOP.queryStringSchemaUrl["ots_token"])) &&
             !MOP.Utilities.empty(MOP.queryStringSchemaUrl["forcePage"])) {
          
          MOP.queryStringSchemaUrl.automatic_login = true;
          MOP.queryStringSchemaUrl.hidden_login = true;
          
        } else if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["sessionid"]) || !MOP.Utilities.empty(MOP.queryStringSchemaUrl["ots_token"])) {
          
          MOP.queryStringSchemaUrl.automatic_login = true;
          MOP.queryStringSchemaUrl.hidden_login = false; 
        }
        
        if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["sessionid"]) || !MOP.Utilities.empty(MOP.queryStringSchemaUrl["ots_token"])) {
          if (!MOP.Utilities.empty(MOP.config)) {
            var login_data = {};
            if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["ots_token"])) {
              login_data['ots_token'] = MOP.queryStringSchemaUrl["ots_token"]
            } else {
              login_data['sessionid'] = MOP.queryStringSchemaUrl["sessionid"];
            }

            MOP.LM.doLogin( login_data,
                  null,
                  null,
                  MOP.queryStringSchemaUrl.automatic_login,
                  MOP.queryStringSchemaUrl.hidden_login    
            ).done(function () {
              
              if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["forcePage"])) {
                
                var forceRoute = null;
                
                if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["forceRoute"])) {
                  
                  forceRoute = MOP.queryStringSchemaUrl["forceRoute"];
                }
                
                return MOP.changePage(MOP.queryStringSchemaUrl["forcePage"],forceRoute)
              }
              
            })
             
          } else {
            // Se non è stato ancora caricato il MOP.config qui non facciamo niente a parte settare un flag perchè vuol dire che l'app
            // è completamente chiusa in questo caso in automatico sarà app.js a leggere MOP.queryStringSchemaUrl["sessionid"|"ots_token"]
            // e fare il login al momento opportuno
            MOP.queryStringSchemaUrl["doLoginForSchemaUrl"] = true;
          }
        }
        
        if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["forcePage"])) {
          if (MOP.Utilities.empty(MOP.config)) {
            // Se non è stato ancora caricato il MOP.config qui non facciamo niente a parte settare un flag perchè vuol dire che l'app
            // è completamente chiusa in questo caso in automatico sarà pages_modules a leggere MOP.queryStringSchemaUrl["forcePage"]
            // e fare il cambio pagina al momento opportuno
            MOP.queryStringSchemaUrl["doChangePageForSchemaUrl"] = true;
          }
        }

        // Se siamo qui allora siamo nel flusso del pagamento tramite app
        if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["auth_mode"]) && MOP.queryStringSchemaUrl["auth_mode"] === MOP.constants.AUTH_MODE_FROM_PAYMENT) {

          SafariViewController.hide()

          const { response, alertType, alertMsg, userid, type } = MOP.queryStringSchemaUrl;

          const notification = {
            type: alertType,
            msg: alertMsg
          };

          if (type === MOP.constants.PAYMENT_RESERVATION) {

            const { resid } = MOP.queryStringSchemaUrl;
            if (response === 'success' || alertType === 'warning') {
              setTimeout(() => {
                return MOP.goToPrintReservation(resid, userid, notification, true);
              }, 0);

            }

            setTimeout(() => {
              return MOP.goToMyReservations(userid, notification, true);
            }, 0);

          } else if (type === MOP.constants.PAYMENT_DOCUMENT) {
            if (response === 'success') {
              const { downloadid, episodeid } = MOP.queryStringSchemaUrl;

              setTimeout(() => {
                return MOP.changePage('dossier', `dossier/user/${userid}/episode/${episodeid}/documents/${downloadid}/forced`, null, null, notification, null, null, null, null, null, true);
              }, 0);
            } else {
              const { episodeid } = MOP.queryStringSchemaUrl;

              setTimeout(() => {
                return MOP.changePage('dossier', `dossier/user/${userid}/episodes/${episodeid}`, null, null, notification, null, null, null, null, null, true);
              }, 0);

            }
          } else if (type === MOP.constants.PAYMENT_REQUEST) {
              if (response === 'success') {
                const { requestid } = MOP.queryStringSchemaUrl;
                
                setTimeout(() => {
                  return MOP.changePage('payment', `payment/request/${requestid}/userid/${userid}/success`, null, null, notification, null, null, null, null, null, true);
                }, 0);
              } else {
              const { requestid } = MOP.queryStringSchemaUrl;
              
              setTimeout(() => {
                return MOP.changePage('payment', `payment/request/${requestid}/userid/${userid}/failed`, null, null, notification, null, null, null, null, null, true);
              }, 0);
            }
          }

        
        } // Se siamo qui allora siamo nel flusso di spid tramite app
        if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["auth_mode"]) && MOP.queryStringSchemaUrl["auth_mode"] === AUTH_MODE_FROM_SPID) {

          MOP.TM.blockLoading();

          SafariViewController.hide();

          const { forceRoute, forcePage, tokenid } = MOP.queryStringSchemaUrl;

          // se riceviamo il tokenid vuol dire che dobbiamo passare per un login (utente già registrato)
          // e poi fare redirect alla pagina che abbiamo in force_page force_route
          
          if(tokenid) {
            // Proveniamo dallo spid, andiamo a chiamare l'api che ci restituisce il paziente
            
            getSpidAppToken(tokenid).then(token => {

              const { entity_data: { sessionid } } = token;
              const login_data = { sessionid };

              MOP.LM.doLogin(login_data).done(login => {

                const { return: user, result } = login;

                if(result === "OK") {

                  MOP.setSession(sessionid, user);

                  setTimeout(() => {
                    return MOP.changePage(forcePage, forceRoute, null, null, null, null, null, null, null, null, true);
                  }, 0);

                }
              });
            })
            
          } else {
            // se invece tokenid non è presente, allora facciamo solo il redirect alla force_page e force_route (che sarà un redirect verso la spid login)
            setTimeout(() => {
              return MOP.changePage(forcePage, forceRoute, null, null, null, null, null, null, null, null, true);
            }, 0);

          }






        }
      }
    }

    function insertAfterNode(el, referenceNode) {
      referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
    }

    function insertBeforeNode(el, referenceNode) {
      referenceNode.parentNode.insertBefore(el, referenceNode);
    }

    function upperCaseFirstLetterEveryWord(str) {
      var lower = String(str).toLowerCase();
      return lower.replace(/(^| )(\w)/g, function(x) {
        return x.toUpperCase();
      });
    }

    function realEmpty(mixed_var) {
      var key;

      if (mixed_var === "" ||
          mixed_var === null ||
          mixed_var === "null" ||
          typeof mixed_var === 'undefined' ||
          mixed_var === "undefined"
      ){
          return true;
      }

      if (typeof mixed_var == 'object') {
          for (key in mixed_var) {
            return false;
          }
          return true;
      }
      return false;
    }


    function getForgotPasswordAjaxParams(MOP, data){

      let return_success = {page: 'profile', route: 'profile/expired'};
      const return_error = {page: 'login', route: 'login'};

      // Se proveniamo dalle dispo usiamo la rotta delle dispo come pagina da aprire per reimpostare la password
      // poi verrà fatto in automatico un redirect all'expired che una volta finito ridirezionerà alla pagina delle availabilties
      if (MOP.localStorage.has('last_availabilities_additional_parameters')) {
        const ava_params = MOP.localStorage.get('last_availabilities_additional_parameters');
        if (!MOP.Utilities.empty(ava_params.page_route)) {
          return_success = ava_params.page_route;
        }
      } else if (MOP.localStorage.has('anchorPage') && MOP.localStorage.has('anchorPageRoute')) {
        return_success = {page: MOP.localStorage.get('anchorPage'), route: MOP.localStorage.get('anchorPageRoute')};
      }

      let toSendData = {
        ...data,
        return_success,
        return_error
      }

      var ajaxRestParams = {
      searchPath: "users/_forgot",
        ajax: {
          options: {
            method: 'PUT'
          },
          data: toSendData
        },
        loading: {
          enabled: true
        }
      };

      return ajaxRestParams;
    };

    var GoogleMapsModule = {

    initializeGoogleMap: function(map_id, zoom, lat, lng) {
      window.geocoder   = new google.maps.Geocoder();
      window.bounds_map = new google.maps.LatLngBounds();

      if( !lat ){
          lat = 44.495508; // default tuotempo (via Ugo Bassi, 11 Bologna)
      }
      if( !lng ){
          lng = 11.339789; // default tuotempo (via Ugo Bassi, 11 Bologna)
      }
      var bounds = new google.maps.LatLngBounds();
      var latlng = new google.maps.LatLng( lat, lng );
      var myOptions = {
          zoom: zoom,
          center: latlng,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          scrollwheel: false,
          draggable: false
      };
      window.google_map = new google.maps.Map(document.getElementById( map_id ), myOptions);
      
      // Per il momento spegniamo perchè non usato
      //sentryCaptureMessage(MOP, 'Utilities New Map Request');
      
      return {map: window.google_map,
              bounds : bounds };
    },

    loadGoogleMapByPositions: function(map_id, positions) {
      var map_obj = GoogleMapsModule.initializeGoogleMap( map_id );
      for (var i=0; i < positions.length; i++) {
          var position = positions[i];
          GoogleMapsModule.addGoogleMapMarker( map_obj, position );
      }
      GoogleMapsModule.resizeGoogleMap( map_obj, true );
      return map_obj;
    },

    resizeGoogleMap: function(map_obj, fit_bounds) {
      google.maps.event.trigger(map_obj.map, 'resize');
      if( fit_bounds ){
          map_obj.map.fitBounds( map_obj.bounds );
      }
      return map_obj;
    },

    addGoogleMapMarker: function(map_obj, position) {
      var lat    = position.latitude;
      var lng    = position.longitude;

      if (lat && lng) {
        var title  = position.title || "";
        var latlng = new google.maps.LatLng( lat, lng );
        map_obj.map.setCenter( latlng );
        map_obj.bounds.extend( latlng );

        var map_marker = new google.maps.Marker({
            position: latlng,
            map: map_obj.map
        });

        var iw = new google.maps.InfoWindow({
            content: title
        });

        // Vedi http://goo.gl/bAZYU9
        google.maps.event.addListener(map_marker, 'click', (function (map_marker) {
            //iw.open(map_obj.map, map_marker);
        })(map_marker));

        return map_obj;
      }
    },

    loadGoogleMapByAddress: function(MOP, address, lat, lng, map_id, zoom, map_container_id, map_link_id) {
      GoogleMapsModule.initializeGoogleMap(map_id, zoom);

      if( !empty(lat) && !empty(lng) ){
        var latlng = new google.maps.LatLng( lat, lng );

        if( map_container_id ){
          MOP.templateModule.show(map_container_id);
        }
        if( map_link_id ){
          MOP.templateModule.show(map_link_id);
        }

        var marker = new google.maps.Marker({
          map: google_map,
          position: latlng,
          title: address
        });

        setTimeout(function() {
          google.maps.event.trigger(google_map, 'resize');
          google_map.setCenter( latlng );
        }, 200);

      } else {
        geocoder.geocode( {'address': address}, function(results, status) {
          if (status == google.maps.GeocoderStatus.OK) {

            if( map_container_id ){
              MOP.templateModule.show(map_container_id);
            }
            if( map_link_id ){
              MOP.templateModule.show(map_link_id);
            }

            var marker = new google.maps.Marker({
              map: google_map,
              position: results[0].geometry.location,
              title: address
            });

            setTimeout(function() {
              google.maps.event.trigger(google_map, 'resize');
              google_map.setCenter( results[0].geometry.location );
            }, 200);
          } else {
            if( map_container_id ){
              MOP.templateModule.hide(map_container_id);
            }
            if( map_link_id ){
              MOP.templateModule.hide(map_link_id);
            }
          }
        });
      }
    }
  };

  /**
  * Validate custom types
  */
  var validator = {

    /**
    * Check if is a valid phone number
    * @param  {String} number - input phone number
    * @return {Boolean} - the result of checking
    */
    isPhoneNumber: function(number) {

      if (typeof number !== 'string') {
        throw new TypeError('The parameter has to be string');
      }

      var phoneno = /^\+?([0-9]{5,})$/;
      return phoneno.test(number);
    },

    /**
    * Check if is a valid email
    * @param  {String} email - input email
    * @return {Boolean} - the result of checking
    */
    isEmail: function(email) {

      if (typeof email !== 'string') {
        throw new TypeError('The parameter has to be string');
      }

      //var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var re = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
      return re.test(email);
    }
  };
  
function makeDeferredRequest (MOP,data,loadingEnabled,successCallback,failCallback,deferredObject, summaryDeferredFamilyUserId = null) {
  let dataRequest = {};
  const userid = MOP.getLoggedUser().id

  const staticUser = MOP.request('user:entity:static');
  const reservationUsersByPatient = staticUser.getReservationUsersByPatient(MOP, userid);
  const selectedReservationUserid = staticUser.getSelectedReservationUserid(reservationUsersByPatient);

  if (!MOP.Utilities.empty(deferredObject.insuranceid)) {
    dataRequest.insuranceid = deferredObject.insuranceid;
  }

  if (!MOP.Utilities.empty(deferredObject.areaid)) {
    dataRequest.areaid = deferredObject.areaid;
  }

  dataRequest.activityTitle = deferredObject.activityTitle;
  dataRequest.typologyTitle = deferredObject.typologyTitle;
  dataRequest.areaTitle = deferredObject.areaTitle;
  dataRequest.areaAddress = deferredObject.areaAddress;
  dataRequest.requested_for_userid = summaryDeferredFamilyUserId ? summaryDeferredFamilyUserId : selectedReservationUserid;
  dataRequest.activityTags = deferredObject.activityTags;

  dataRequest.prescription_code = deferredObject.prescription_code;
  dataRequest.prescription_doctor = deferredObject.prescription_doctor ;
  dataRequest.prescription_priority = deferredObject.prescription_priority;
  dataRequest.prescription_summary = deferredObject.prescription_summary;

  if(!MOP.Utilities.empty(deferredObject.from_fallback)){
    dataRequest.from_fallback = deferredObject.from_fallback;
  }

  if(!MOP.Utilities.empty(deferredObject.resourceName)){
    dataRequest.resourceName = deferredObject.resourceName
  }

  if (!MOP.Utilities.empty(deferredObject.resourceid)) {
    dataRequest.resourceid = deferredObject.resourceid;
  }

  if (!MOP.Utilities.empty(deferredObject.areaRegion)) {
    dataRequest.areaRegion = deferredObject.areaRegion;
  }

  if (!MOP.Utilities.empty(deferredObject.areaProvince)) {
    dataRequest.areaProvince = deferredObject.areaProvince;
  }

  if (data) {
    dataRequest = _.extend({}, dataRequest, data);
  }

  const params = {
    searchPath: `activities/${deferredObject.activityid}/_apprequest`,
    ajax: {
      options: {
        method: 'POST'
      },
      data: dataRequest
    },
    loading: {
      enabled: loadingEnabled
    }
  };

  MOP.ajaxRest(params)
      .done(resp => {
        MOP.Utilities.log(MOP, resp);
        if(successCallback){
          successCallback(resp);
        }
      })
      .fail(e => {
        MOP.Utilities.log(MOP, e);
        if(failCallback) {
          failCallback(e);
        }
      });
};
  function getDeviceLanguage(MOP) {
    let lang = {
      deviceLang: null,
      defaultLang: 'en'
    };
    var deviceLanguage = navigator.language || navigator.userLanguage; //ritorna es. en-US
    if (!MOP.Utilities.empty(deviceLanguage)) {
      var splitLang = [];
      if (deviceLanguage.toLowerCase().indexOf("pt") != -1 && deviceLanguage.toLowerCase().indexOf("br") == -1) {
        splitLang[0] = 'pt_PT';
      } else if (deviceLanguage.toLowerCase().indexOf("pt") != -1 && deviceLanguage.toLowerCase().indexOf("br") != -1) {
          splitLang[0] = 'pt_BR';
        }else {
          splitLang = deviceLanguage.split('-');
        }
      if (splitLang.length != 0) {
        lang.deviceLang = splitLang[0];
      }
    }
    return lang;
  }

  function loadRecoveryDeviceLang(MOP) {
    const lang =  MOP.Utilities.getDeviceLanguage(MOP);
    /** 
     * ? GoodbyePHP - Compatibilità PHP / HTML
     * * Questo pezzo di codice implementa una compatibilità tra il vecchio flusso PHP ed il nuovo HTML
     * * se ci saranno problemi sul task dopo il rilascio e dobbiamo spegnere il config, 
     * * allora tutti i punti con questo commento devono manualmente essere revertati
     */
    return MOP.DM.loadjscssfile(MOP.config.getStaticPath() + 'lang/specific/doctor.' + lang.deviceLang + '.lang.js?' + MOP.config.get("langSpecificVersion"))
    .then(function() {
      return MOP.Utilities.updateGlobalLang(window.MOP_globals.lang, window.MOP_globals.spec_lang);
    })
    .then(function () {
    /** 
     * ? GoodbyePHP - Compatibilità PHP / HTML
     * * Questo pezzo di codice implementa una compatibilità tra il vecchio flusso PHP ed il nuovo HTML
     * * se ci saranno problemi sul task dopo il rilascio e dobbiamo spegnere il config, 
     * * allora tutti i punti con questo commento devono manualmente essere revertati
     */
      return MOP.DM.loadjscssfile(MOP.config.getStaticPath() + `lang/specific/doctor.${lang.defaultLang}.lang.js?` + MOP.config.get("langSpecificVersion"));
    })
    .then(function() {
      return MOP.Utilities.updateGlobalLang(window.MOP_globals.lang, window.MOP_globals.spec_lang);
    })
  }

  function completProfileFlowToAddReservation(MOP, alert = null){
    MOP.localStorage.set("complete_profile_flow_proceed", true);
    const page_route = MOP.localStorage.get('complete_profile_flow_params_to_add_reservation')["page_route"];
    return MOP.changePage(page_route.page, page_route.route, null, null, alert);
  };

  function dateType(MOP,type) {
    const dateType = {};
    switch (parseInt(type, 10)) {
      case MOP.config.constants.INPUT_FIELD_DATE_DDMMYYYY:
        dateType.placeholder = `(${TTPolyglot.t('ddmmyyyy', null, true)})`;
        dateType.pattern = ['d', 'm', 'Y'];
        break;
      case MOP.config.constants.INPUT_FIELD_DATE_MMYYYY:
        dateType.placeholder = `(${TTPolyglot.t('mmyyyy', null, true)})`;
        dateType.pattern = ['m', 'Y'];
        break;
      case MOP.config.constants.INPUT_FIELD_DATE_YYYY:
        dateType.placeholder = `(${TTPolyglot.t('yyyy', null, true)})`;
        dateType.pattern = ['Y'];
        break;
      default:
        break;
    }
    return dateType;
  }

  function externalLinkProtocolValidator(MOP, link){
    if (MOP.Utilities.empty(link))
      return null;

    if (link.indexOf("http") == 0)
      return link;

    return `https://${link}`;
  }

  /*
  * Get 
  * @param {Object} [MOP] - a MOP instance
  * @param {String} startHumanDate - the start date in human readable format
  * @param {String} endHumanDate - the end date in human readable format
  * @return {Int} - the diff between the two dates
  */
  function getHumanDateDaysDiff(MOP, startHumanDate, endHumanDate) {
    const startDate = MOP.Utilities.humanDateToDateObj(MOP, startHumanDate, "12:00");
    const endDate = MOP.Utilities.humanDateToDateObj(MOP, endHumanDate, "12:00");

    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    return diffDays;
  };


    /*
  * Get 
  * @param {Object} [MOP] - a MOP instance
  * @param {String} array - an array of object
  * @param {String} property - the property name of the object we want to retrieve the min value
  * @return {Int} - the min value of the property in the array, -1 if not exist
  */
    function getMinPropertyValueInObjArray(MOP, array, property) {
      let result = -1;
      
      const objectsWithProperty = array.filter(obj => !MOP.Utilities.empty(obj[property])); 
      if(objectsWithProperty.length === 0)
        return result;
    
      result = Math.min.apply(Math, objectsWithProperty.map((obj) => { 
        // Trasformo il numero in float per non perdere eventuali cifre decimali
        return parseFloat(obj[property]); 
      }));

      // Se il numero minimo è un intero ed è in formato N.00, trasformo il numero
      // in intero per ripulirlo dalle cifre decimali
      if(Number.isInteger(result))
        return parseInt(result);

      return result;
    };


  var UtilitiesPublicFunctions = {
    empty: empty,
    getDistanceFromLatLonInKm: getDistanceFromLatLonInKm,
    myPositionIn: myPositionIn,
    isPortalInstancePrivacyEnabled: isPortalInstancePrivacyEnabled,
    loadjscssfile: loadjscssfile,
    loadGoogleAPI: loadGoogleAPI,
    loadGooglePlacesAPI: loadGooglePlacesAPI,
    loadGoogleCriterionAddressAPI: loadGoogleCriterionAddressAPI,
    convertMeasureOrder: convertMeasureOrder,
    Pollinger: Pollinger,
    hexToRgb: hexToRgb,
    triggerExternalEvent: triggerExternalEvent,
    formatURL: formatURL,
    formatTime: formatTime,
    addSlashes: addSlashes,
    getWeekDay: getWeekDay,
    addDays: addDays,
    removeDays: removeDays,
    getDownloadFileUrl: getDownloadFileUrl,
    formatPageRoute: formatPageRoute,
    ajax: ajax,
    isMobileNavigator: isMobileNavigator,
    isMobileApp: isMobileApp,
    isAndroidDevice: isAndroidDevice,
    isAppleDevice: isAppleDevice,
    isIpad: isIpad,
    isUndefined: isUndefined,
    isOpera: isOpera,
    isFirefox: isFirefox,
    isSafari: isSafari,
    isChrome: isChrome,
    isIE: isIE,
    getInternetExplorerVersion: getInternetExplorerVersion,
    round: round,
    formatDistanceToPrint: formatDistanceToPrint,
    formatTimeFromSeconds: formatTimeFromSeconds,
    convertTimeInMinutes: convertTimeInMinutes,
    getTodayTimestamp: getTodayTimestamp,
    getTimestamp: getTimestamp,
    unixTimeToHourMinute: unixTimeToHourMinute,
    getPublicURL: getPublicURL,
    weAreOnProduction: weAreOnProduction,
    weAreOnDevelopment: weAreOnDevelopment,
    log: log,
    encodeQuery: encodeQuery,
    decodeQuery: decodeQuery,
    extractForgotPasswordOptions: extractForgotPasswordOptions,
    dateObjToHumanDate: dateObjToHumanDate,
    humanDateToDateObj: humanDateToDateObj,
    humanDateToDateObjDate: humanDateToDateObjDate,
    humanDateToDateObjYear: humanDateToDateObjYear,
    getInstanceDateFormat: getInstanceDateFormat,
    removeParameterFromUrl: removeParameterFromUrl,
    getDeviceEventsFromCalendar: getDeviceEventsFromCalendar,
    isExternalPayment: isExternalPayment,
    openUrlInNewTabWithPostParameters: openUrlInNewTabWithPostParameters,
    objectToQueryString: objectToQueryString,
    orderArrayByProperty: orderArrayByProperty,
    getAgeFromDate: getAgeFromDate,
    getParameterByName: getParameterByName,
    openRightMap: openRightMap,
    existParamInQueryString: existParamInQueryString,
    makeRandomID: makeRandomID,
    humanDateLongFormat: humanDateLongFormat,
    translateIDType: translateIDType,
    getMonthsArray: getMonthsArray,
    getWeekDaysArray: getWeekDaysArray,
    getWeekDaysAbbreviatedArray: getWeekDaysAbbreviatedArray,
    createCancelDeferredForm: createCancelDeferredForm,
    getDownloadDocumentUrl: getDownloadDocumentUrl,
    hexToRGB: hexToRGB,
    sentryCaptureMessage: sentryCaptureMessage,
    getWeekDaysArrayFromSunday: getWeekDaysArrayFromSunday,
    sentryAddBreadcrumb: sentryAddBreadcrumb,
    sendAppEvents: sendAppEvents,
    createSchemaUrl: createSchemaUrl,
    dateObjToHumanTime: dateObjToHumanTime,
    handleSchemaUrl: handleSchemaUrl,
    insertAfterNode: insertAfterNode,
    insertBeforeNode: insertBeforeNode,
    dateObjToHumanDateTime: dateObjToHumanDateTime,
    upperCaseFirstLetterEveryWord: upperCaseFirstLetterEveryWord,
    getDownloadDocumentUrlWithToken: getDownloadDocumentUrlWithToken,
    updateQuerystringUrlParameter: updateQuerystringUrlParameter,
    realEmpty: realEmpty,
    dateObjToHumanDateLongFormat: dateObjToHumanDateLongFormat,
    unixToDayAndDayWeek: unixToDayAndDayWeek,
    millisToMinutesAndSeconds: millisToMinutesAndSeconds,
    getForgotPasswordAjaxParams: getForgotPasswordAjaxParams,
    makeDeferredRequest:makeDeferredRequest,
    disconnectAlertInformation: disconnectAlertInformation,
    loadRecoveryDeviceLang:loadRecoveryDeviceLang,
    getDeviceLanguage: getDeviceLanguage,
    completProfileFlowToAddReservation: completProfileFlowToAddReservation,
    dateType: dateType,
    externalLinkProtocolValidator: externalLinkProtocolValidator,
    getHumanDateDaysDiff: getHumanDateDaysDiff,
    getMinPropertyValueInObjArray: getMinPropertyValueInObjArray,
    createDeferredForm: createDeferredForm,
    handleFormRedirection: handleFormRedirection,
    handleSSOFormRedirection: handleSSOFormRedirection,
    createHiddenFormInput: createHiddenFormInput
  };

  UtilitiesPublicFunctions.validator = validator;
  UtilitiesPublicFunctions.share = share;
  UtilitiesPublicFunctions.ApiV3Module = ApiV3Module;
  UtilitiesPublicFunctions.ApiV2Module = ApiV2Module;
  UtilitiesPublicFunctions.GoogleMapsModule = GoogleMapsModule;

  export default UtilitiesPublicFunctions;
