export const getBaseUrl = () => {
  const { protocol, hostname } = window.location;
  return `${protocol}//${hostname}`;
};

export const getMOPBaseUrl = () => {
  return `${getBaseUrl()}/mop`;
};

export const getImageBaseUrl = (isMobileApp = false) => {
  if (isMobileApp) {
    return `assets/img`;
  }
  return `${getBaseUrl()}/mop/assets/img`;
};


/** addParamsToUrl
 * Riceve in ingresso una stringa url ed un oggetto { key: value }
 * aggiunge i parametri all'url utilizzando le API javascript
 * togliendo i parametri vuoti
 * @param {string} url 
 * @param {object} params 
 * @returns {string}
 */
export const addParamsToUrl = (url, keyValueParams = {}) => {
  try {
    const _url = new URL(url);

    Object.keys(keyValueParams).map(key => {
      const value = keyValueParams[key];
      if(value !== null && value !== undefined && value !== "") _url.searchParams.set(key, value);
    });

    return _url.toString();
  } catch (e) {
    return url;
  }
};


/**
 * Convert a querystring to Object.
 * @param {String} querystring - the querystring to convert
 * @return {Object}
 */
export const querystringToObject = (querystring, allowUndefinedValues) => {
  if (typeof querystring !== 'string') {
    throw new TypeError('The parameter has to be string');
  }

  const a = querystring.split('&');
  if (a === "") {
    return {};
  }

  const forceRouteIndex = a.findIndex(x => x.includes('forceRoute'));

  if (forceRouteIndex != -1) {
    // è possibile passare una route con parametri, ma solo se i parametri sono preceduti 
    // da /query e delimitati da un / finale, esempio:
    // index.php?dbName=tt_salus&forcePage=search&forceRoute=search/query/test=1&prova=1/&nocache=1
    // dove test e prova sono parametri che fanno parte di forceRoute, mentre nocache no
    const hasQuery = a[forceRouteIndex].includes('query/');

    if (hasQuery) {
      const firstParam = a[forceRouteIndex].split('query/')[1];
      const isLast = firstParam.includes('/');
      const firstParamClean = firstParam.split('/')[0];
      a[forceRouteIndex] = a[forceRouteIndex].split('query/')[0] + 'query/' + firstParamClean;
      
      if (!isLast) {
        for (let i = forceRouteIndex + 1; i < a.length; ++i) {
          const v = '&' + a[i];
          a[forceRouteIndex] += v.split('/')[0];
          a[i] = undefined;
          if (v.includes('/')) {
            break;
          }
        }
      }
    }
  }
  
  const b = {};
  for (let i = 0; i < a.length; ++i) {
    if (!a[i]) continue;

    const val = a[i].indexOf('=') > 0 ? a[i].substring(a[i].indexOf('=') + 1) : undefined;
    const key = a[i].split('=')[0];
    if (val !== undefined) {
      b[key] = decodeURIComponent(val.replace(/\+/g, " "));
    } else if (allowUndefinedValues) {
      b[key] = undefined;
    }
  }
  return b;
};


export const isNet = () => {
  return window.location.host.indexOf('.net') !== -1;
};

export const isBeta = () => {
  return window.location.host.indexOf('beta.tuotempo.com') !== -1;
};

export const isProduction = () => {
  return window.location.host.indexOf('app.tuotempo.com') !== -1;
};

export const isTestProduction = () => {
  return window.location.host.indexOf('backend.tuotempo.com') !== -1;
};