import $ from 'jquery';
import _ from 'underscore';
import Cleave from "cleave.js";

import { TTPolyglot } from "@frontend/tt-polyglot";

export default function (MOP) {

    /** @alias module:templateModule */
    var TM = {

        init: function init() {
            TM.addScreenCss();
        },

        /**
         * Returns the current event triggered
         *
         * @param e - browser events
         * @returns {Event}
         */
        getEvent: function (e) {
            return e || window.event;
        },

        /**
         * Avoid that the event passed as parameter will
         * be propaged in the bubbling phase
         *
         * @param e - event
         * @returns {boolean}
         */
        stopPropagation: function (e) {
            if (typeof e.stopPropagation === 'function') {
                e.stopPropagation();
            } else {
                e.cancelBubble = true;
            }

            return false;
        },

        disableEvent: function (e) {
            TM.stopPropagation(e);

            if (typeof e.preventDefault === 'function') {
                e.preventDefault();
            }

            return false;
        },

        /**
         * Returns the triggering element
         *
         * @param e - event triggered
         * @returns {e.target|*|Object|searchParams.srcElement}
         */
        getTarget: function (event) {
            return event.target || event.srcElement;
        },

        getCurrentTarget: function getCurrentTarget(event) {
            return (event.currentTarget) ? event.currentTarget : event.srcElement;
        },

        /**
         * Check if the elem is an element of the type tag_name
         *
         * @param elem
         * @param {string} tag_name
         * @returns {boolean}
         */
        is: function (elem, tag_name) {
            return elem.tagName === tag_name.toUpperCase();
        },

        /**
         * Set the selector elements visible
         *
         * @param selector
         */
        show: function (selector) {
            var $elem = $(selector);
            $elem
                .removeClass('tt-hide');
            this.resizeThisFrame();
        },

        /**
         * Set the selector elements hide
         *
         * @param selector
         */
        hide: function (selector) {
            var $elem = $(selector);
            if ($elem.length) {
                $.each($elem, function (i, val) {
                    var $val = $(val);

                    $val.removeClass('show');

                    if (!$val.hasClass('tt-hide')) {
                        $val.addClass('tt-hide');
                    }
                });
            } else {
                $elem.removeClass('show');

                if (!$elem.hasClass('tt-hide')) {
                    $elem.addClass('tt-hide');
                }
            }

            this.resizeThisFrame();
        },

        /**
         * Return the next element having the passed tagName and classname
         *
         * @param elem - current element
         * @param tagName - tag name of the searched element
         * @param [class_name=''] - class name of the searched element
         * @returns {DOM-Node|null} - returns the element found or null
         */
        next: function (elem, tagName, class_name) {
            if (MOP.Utilities.empty(elem)) {
                return null;
            }

            elem = elem.nextSibling;
            class_name = class_name || '';
            tagName = tagName.toUpperCase();

            while (
                !MOP.Utilities.empty(elem) &&
                (elem.tagName !== tagName || (elem.tagName !== tagName && this.hasClass(elem, class_name)))
            ) {
                elem = elem.nextSibling;
            }
            return elem;
        },

        /**
         * Return the first precedent element of kind tagName having
         * the class class_name
         *
         * @param elem - current element
         * @param tagName - tag name of the searched element
         * @param [class_name=''] - class name of the searched element
         * @returns {DOM-Node|null} - returns the element found or null
         */
        prev: function (elem, tagName, class_name) {
            if (MOP.Utilities.empty(elem)) {
                return null;
            }

            elem = elem.previousSibling;
            tagName = tagName.toUpperCase();
            class_name = class_name || '';

            try {
                while (
                    !MOP.Utilities.empty(elem) &&
                    (elem.tagName !== tagName || (elem.tagName === tagName && !this.hasClass(elem, class_name)))
                ) {
                    elem = elem.previousSibling;
                }
                return elem;
            } catch (e) {
                //console.log(elem);
            }
        },

        /**
         * Check if elem has the class_name class
         *
         * @param elem
         * @param {string} class_name
         * @returns {boolean}
         */
        hasClass: function (elem, class_name) {
            if (MOP.Utilities.empty(elem)) {
                return false;
            }
            if (class_name === '') {
                return true;
            }

            return elem.className.indexOf(class_name) > -1;
        },

        /**
         * Add to elem the class_name class
         *
         * @param elem
         * @param {string} class_name
         * @returns {*}
         */
        addClass: function (elem, class_name) {
            var currentClass = elem.className;
            elem.className = currentClass + ' ' + class_name;
            return elem;
        },

        /**
         * Remove from the elem the class_name class
         *
         * @param elem
         * @param class_name
         */
        removeClass: function (elem, class_name) {
            if (this.hasClass(elem, class_name)) {
                elem.className = elem.className.replace(class_name, "");
            }

            return elem;
        },

            /**
             * Scroll the page to the coordinate specified by x and y
             *
             * @param {number} x
             * @param {number} y
             */
            scrollTo: function(x, y, smooth){
                if (typeof x === 'number' && typeof y === 'number'){
                    if (MOP.config.isInIFrame() && !MOP.config.isOldLauncher(MOP) && !MOP.config.isNewLauncher(MOP)) {
                        var funcToEval = (y === 0) ? 'resp.y = getNodePosition ? getNodePosition(document.getElementById("mop_iframe"))[0] : 0;' : '';
                        
                        
                        // Se siamo in iFrame e se mopScrollTopOffset non è vuoto, vuol dire che siamo in una situazione in cui c'è una navbar
                        // fixed, e tutto il contenuto del sito passa sotto la navbar, per evitare che quando facciamo scrolltop andiamo a finire sotto
                        // la navbar nascondendo parte del mop, andiamo ad effettuare questa logica
                        if (x == 0 && y == 0 && !MOP.Utilities.empty(MOP.config.getInstanceConfig('mopScrollTopOffset'))) {
                          var mopScrollTopOffset = parseInt(MOP.config.getInstanceConfig('mopScrollTopOffset'));

                          //l'altezza dell'iframe viene calcolata con getBoundingClientRect().top che si riferisce alla distanza dell'iframe relativa alla viewport
                          // viene sommato poi window.pageYOffset perchè nel caso venisse ricaricata la pagina ,la funzione getBoundingClientRect() prende in considerazione anche lo scroll prima del refresh della pagina
                          //in questo modo l'iframe sarà sempre nella posizione esatta e non avrà margin-top non necessari

                        funcToEval = funcToEval + 'var iframeY = document.getElementById("mop_iframe").getBoundingClientRect().top + window.pageYOffset; var mopScrollTopOffset = ' + mopScrollTopOffset + ';';
                        // Se la posizione y dell'iFrame è minore dell'altezza della navbar vuol dire che sopra l'iframe, non c'è abbastanza spazio
                        // per poter effettuare uno scroll che ci porti sotto la navbar, in questi casi allora andiamo ad aggiungere un margin-top,
                        // altrimenti in tutti gli altri casi andiamo a scrollare di y dell'altezza della navbar + la posizione y dell'iFrame
                        funcToEval = funcToEval + 'if (iframeY >= mopScrollTopOffset) { resp.y = iframeY - mopScrollTopOffset } else { document.getElementById("mop_iframe").style.marginTop = (mopScrollTopOffset+iframeY)+"px"; }';
                    } else if (x == 0 && y != 0) {
											funcToEval = funcToEval + 'var iframeY = document.getElementById("mop_iframe").offsetTop; resp.y = ' + y + ' + iframeY;';
                    }


										if (smooth == true) {
											MOP.sendMessageToParentFrame({ fn: 'evalFunction', value: funcToEval + 'window.scrollTo({top: resp.y, behavior: "smooth"});', resp: { x: x, y: y } });
										} else {
											MOP.sendMessageToParentFrame({ fn: 'evalFunction', value: funcToEval + 'window.scrollTo(resp.x, resp.y);', resp: { x: x, y: y } });
										}
                } else {
									if (smooth == true) {
                    if(MOP.config.isMobile()) {
                      document.getElementById('main-wrapper-content-region').scrollTo({top: y, behavior: "smooth"});
                    } else {
                      window.scrollTo({top: y, behavior: "smooth"});
                    }
										
									} else {
                    if(MOP.config.isMobile()) {
                      document.getElementById('main-wrapper-content-region').scrollTo(x,y);
                    } else {
                      window.scrollTo(x, y);
                    }
									}
                }
            }

        },

        /**
         * Scroll to the top of the current page
         */
        scrollTop: function (smooth = false) {
            // se vogliamo bloccare lo scrollTop al caricamento della pagina controlliamo se non è stato ancora caricato nessun modulo,
            // in tal caso siamo al primo caricamento e non va fatto lo scroll
            if (MOP.avoidFirstScrollingTop?.enabled && MOP.Utilities.empty(MOP.avoidFirstScrollingTop?.moduleLoaded)) {
                return;
            }

            return this.scrollTo(0, 0, smooth);
        },

        /**
         * Scroll to the top of the current page, slowly
         */
        scrollTopAnimated: function () {
            $('html, body').animate({ scrollTop: 0 }, 'slow');
        },

        // FIXME [MATTIA] - da spostare
        blockLoading: function (loading_options, replace_main_region, load_custom_adv) {
          if(MOP.Utilities.isMobileApp() && !MOP.state.networkReachability){
            return;
          }
            if (MOP.Common) {
                var loadingView;

                if (load_custom_adv) {
                    loadingView = MOP.Common.Views.view_functions.loadingFactory();
                } else {
                    loadingView = new MOP.Common.Views.Loading(load_custom_adv);
                }

                if (replace_main_region) {
                    MOP.mainRegion.show(loadingView);
                } else {
                    if (this.isHidden($('#loading-region'))) {
                        this.toggleMainRegion('#loading-region');
                    }
                    MOP.loadingRegion.show(loadingView);
                }
            }
        },

        // FIXME [MATTIA] - da spostare
        unblockLoading: function (reset_main_region) {
            if (reset_main_region) {
                MOP.mainRegion.reset();
            } else {
                if (!this.isHidden($('#loading-region'))) {
                    this.toggleMainRegion('#loading-region');
                }
                MOP.loadingRegion.reset();
            }
        },

        blockTypeheadLoading: function blockTypeheadLoading() {
            MOP.TM.show($('.typehead-loading'));
        },

        unblockTypeheadLoading: function unblockTypeheadLoading() {
            MOP.TM.hide($('.typehead-loading'));
        },

        // FIXME [MATTIA] - da spostare
        toggleMainRegion: function (selector) {
            var $elem = $(selector),
                that = this;

            if (this.isHidden($elem)) {
                this.show($elem);
                this.hide('#main-region');
            }

            else {
                this.hide($elem);
                this.show('#main-region');

                $('.header-page').each(function () {
                    that.hide(this);
                });
            }
        },

        /**
         * Check if the element is hidden
         *
         * @param {Object} el - represent an jQuery element
         * @returns {boolean}
         */
        isHidden: function (el) {
            return !el.is(":visible");
        },

        /**
         * Invert the visibility of the passed element
         *
         * @param elem - a DOM element
         */
        invertVisibility: function (elem) {
            var $elem = $(elem);

            if ($elem.hasClass('tt-hide')) {
                this.show($elem);
            } else {
                this.hide($elem);
            }
        },

        /**
         * Clean the value of the passed element
         *
         * @param elem - a DOM element
         */
        clearElementValue: function (el) {
            var $el = $(el);
            $el[0].value = '';
        },

        // FIXME [MATTIA] - da spostare
        clearFormErrors: function (view) {
            var $form = view ? view.find("form") : $('form');

            $form.find(".help-inline.error").each(function () {
                $(this).remove();
            });
            $form.find(".error").each(function () {
                $(this).removeClass("error");
            });

            $form.find(".has-error").each(function () {
                $(this).removeClass("has-error");
            });
        },

        // FIXME [MATTIA] - da spostare
        checkFormRequiredFields: function (view) {
            var deferred = $.Deferred(),
                errors = {};
            $(view).find('[required]').each(function (index, element) {
                var $element = $(element),
                    name = $element.attr('name'),
                    errorMsg = $element.attr('data-validation-error');
                if (MOP.Utilities.empty($(element).val())) {
                    errors[name] = errorMsg;
                }

                deferred.resolve(errors);
            });
            return deferred;
        },

        checkEmailValidationAndConfirm: function (email, confirm) {
            var errors = {};
            if (email.val() != '' || confirm.val() != '') {
                if (!(email.val() == confirm.val())) {
                    errors[email[0].name] = TTPolyglot.t('Dossier Emails Match', null, true);
                    errors[confirm[0].name] = TTPolyglot.t('Dossier Emails Match', null, true);
                } else {
                    if (!MOP.Utilities.validator.isEmail(email.val())) {
                        errors[email[0].name] = TTPolyglot.t('A Valid Email is Required');
                    }
                    if (!MOP.Utilities.validator.isEmail(confirm.val())) {
                        errors[confirm[0].name] = TTPolyglot.t('A Valid Email is Required');
                    }
                }
            }
            return errors;
        },

        validateEmail: function (email) {
            var errors = {};
            if (!MOP.Utilities.validator.isEmail(email.val())) {
                errors[email[0].name] = TTPolyglot.t('A Valid Email is Required');
            }

            return errors;
        },

        _validateEmail: function (email) {
          var errors = {};
          if (!MOP.Utilities.validator.isEmail(email)) {
              errors.email= TTPolyglot.t('A Valid Email is Required');
          }

          return errors;
        },

        // FIXME [MATTIA] - da spostare
        markErrors: function (errors, view) {
            for (var i = 0; i < Object.keys(errors).length; i++) {
                var key = Object.keys(errors)[i];
                this.markError(key, errors[key], view);
            }
        },

        // FIXME [MATTIA] - da spostare
        //Il parametro view è da passare con this.$el dove this è la vista
        markError: function (key, value, view) {
            var selector = "[name='" + key + "']",
                $controlGroup = view ? view.find(selector) : $(selector),
                $parentControlGroup = $controlGroup.parent('div'),
                $errorEl = $("<span>", { "class": "help-inline error", text: value });
            $parentControlGroup.append($errorEl).addClass("error");

        },

        // FIXME [MATTIA] - da spostare
        validationErrorToString: function (validation_error) {
            var string = "";
            for (var key in validation_error) {
                string += (validation_error[key] + "<br/>");
            }

            return string;
        },

        /**
         * Force the current height of the iframe containing the mop, it require that
         * a script is waiting for the request on the parent frame side
         *
         * @param {number} [deltaValue=10] - it represents the minimum value added to the body height
         * @param {boolean} [disableResize=undefined] - if true it disable the resize functionality
         * @param {boolean} [enableResize=undefined] - if true it enable the resize functionality
         */

        // enableBetaMopPage Questa funzione sarà da eliminare quando passeremo ai nuovi criteri
        oldResizeThisFrame: (function () {
          var resizeDisabled = false;

          return function (deltaValue, disableResize, enableResize) {
              if (enableResize) {
                  resizeDisabled = false;
              }

              if (MOP && !resizeDisabled) {
                  if (disableResize) {
                      resizeDisabled = true;
                  }

                  deltaValue = deltaValue || 2;

                      // NON VOGLIAMO IL RESIZE da MOBILE nelle PAGINE di RICERCA
                      // var current_page = MOP.getCurrentPage();
                      // if (MOP.config.isMobile() && current_page.indexOf('search_') !== -1) {
                      //    return;
                      // }
                      if (!MOP.config.isOldLauncher(MOP) && !MOP.config.isNewLauncher(MOP)) {
                        MOP.sendMessageToParentFrame({ fn: 'updateIframeMopHeight', value: $('body').height() + deltaValue });
                      }
                  }
              };
          }()),

        resizeThisFrame: function (delta = 0) {

          if (!MOP.config.isOldLauncher(MOP) && !MOP.config.isNewLauncher(MOP)) {
            MOP.sendMessageToParentFrame({ fn: 'updateIframeMopHeight', value: $('body').height() + delta });
          }
        },

        // FIXME [MATTIA] - da spostare
        showDiscountedPrice: function (activityPrice, discount, payment_required, couponid, discounted_amount) {
            if (!MOP.Utilities.empty(activityPrice)) {
                if ((!MOP.Utilities.empty(discount) || !MOP.Utilities.empty(couponid)) && (!MOP.Utilities.empty(payment_required) || !MOP.Utilities.empty(discounted_amount))) {
                    return true;
                }
            }
            return false;
        },

        /**
         * Invert the visibility of all the elements passed as arguments
         */
        toggleDivs: function () {
            var that = this;
            _.each(arguments, function (elem) {
                that.invertVisibility(elem);
            });
        },

        /**
         * Set the visibility of the passed elements depending
         * on the array they are
         *
         * @param {Object} data - it contain an object having a show and a hide array
         * @param {Object[]} data.show - array of elements to show
         * @param {Object[]} data.hide - array of elements to hide
         */
        updateVisibility: function (data) {
            var that = this,
                toShow = data.show,
                toHide = data.hide;

            _.map(toShow, function (elem) {
                that.show(elem);
            });

            _.map(toHide, function (elem) {
                that.hide(elem);
            });
        },

        closePrint: function (container) {
            document.body.removeChild(container);
        },

        setPrint: function () {
            var contentWindow = {};
            contentWindow.__container__ = this;
            contentWindow.onbeforeunload = TM.closePrint(contentWindow.__container__);
            contentWindow.onafterprint = TM.closePrint(contentWindow.__container__);
            contentWindow.focus(); // Required for IE
            contentWindow.print();
        },

        printPage: function (sURL) {
            var oHiddFrame = document.createElement("iframe");
            oHiddFrame.style.visibility = "hidden";
            oHiddFrame.style.position = "fixed";
            oHiddFrame.style.right = "0";
            oHiddFrame.style.bottom = "0";
            oHiddFrame.src = sURL;

            document.body.appendChild(oHiddFrame);

            oHiddFrame.contentWindow.print();
        },

        openNewTabLink: function (url) {
            window.open(url, MOP.Utilities.isMobileApp() ? '_system' : '_blank');
        },

        showNestedPageBack: function (callback, _this) {
            if ($('.showmenu').length) {
                var $elem = $('.showmenu');
            } else if ($('.showback').length) {
                var $elem = $('.showback');
            } else {
                var $elem = $('body');
            }

            setTimeout(function() {
              $elem
              .removeClass('showmenu')
              .removeClass('showback')
              .addClass('shownestedback');
            }, 0);

            if (callback) {
                var $changeSearchNested = $('#changeSearchNested');

                $(document).off('click', '#changeSearchNested');

                $changeSearchNested.off();
                $changeSearchNested.unbind();
                $changeSearchNested.on('click', function (e) {
                    callback.call(_this, e);
                });
            }
        },

        getCurrentHeaderAction: function () {
            return $('body').hasClass('showback') ? 'showBack' : 'showMenu';
        },

        addEvent: function addEvent(evnt, elem, func) {
            if (elem.addEventListener) {
                elem.addEventListener(evnt, func, false);
            } else if (elem.attachEvent) {
                elem.attachEvent('on' + evnt, func);
            } else {
                // No much to do
                elem[evnt] = func;
            }
        },

        getMainRegionHeight: function getMainRegionHeight() {
            return $(window).height();
        },

        getMainRegionHeightWithoutFooter: function getMainRegionHeightWithoutFooter() {
            return this.getMainRegionHeight() - 60;
        },
        /**
         * Aggiungo nell'header della pagina corrente un style contenente
         * alcune dimensioni relative all'altezza dello schermo corrente.
         */
        addScreenCss: function addScreenCss() {
            var mainRegionHeight = this.getMainRegionHeight();
            var mainRegionHeightWithoutFooter = this.getMainRegionHeightWithoutFooter();
            var halfSize = mainRegionHeight / 2;
            var thirdSize = mainRegionHeight / 3;
            var fourthSize = mainRegionHeight / 4;
            var fifthSize = mainRegionHeight / 5;
            var sixthSize = mainRegionHeight / 6;
            var css = '';

            css += '.screen_half{height:' + halfSize + 'px;}';
            css += '.screen_third{height:' + thirdSize + 'px;}';
            css += '.screen_fourth{height:' + fourthSize + 'px;}';
            css += '.screen_fifth{height:' + fifthSize + 'px;}';
            css += '.screen_sixth{height:' + sixthSize + 'px;}';
            css += '.screen_total{height:' + mainRegionHeight + 'px;}';
            css += '.screen_total_footer{min-height:' + mainRegionHeightWithoutFooter + 'px;}';

            $('<style>' + css + '</style>').appendTo('head');

        },

        ucOnlyFirst: function (str) {
            str += '';
            var f = str.charAt(0)
                .toUpperCase();

            return f + str.substr(1);
        },

        ucFirst: function ucFirst(text) {
            if (!text) {
                return '';
            }

            return text.toLowerCase()[0].toUpperCase() + text.toLowerCase().substring(1);
        },

        ucFirstAll: function ucFirstAll(text) {
            if (!text) {
                return text;
            }

            return _.reduce(text.split(' '), function (acc, word) {
                var computedWord = '';

                // Se sono presenti più di un punto in una parola non la ucfirsto.
                computedWord = ((word.match(/\./g) || []).length > 1) ? word : TM.ucFirst(word);

                    return acc + ' ' + computedWord;
                }, '').trim();
            },

            addHiddenInput: function addHiddenInput(parent, id, name, value) {
              var $ip = $('<input>').attr({
                  type: 'hidden',
                  id: id,
                  name: name,
                  value: value 
              });
              $($ip).appendTo(parent);
            },

        showResourceRating: function handleResourceRating (value, id, align) {
          var defaultAlign = "text-center";
          if (!MOP.Utilities.empty(align)) {
            defaultAlign = align;
          }
          $('#rating-loader-' + id).hide();
          $('#doctor-rating-' + id).rating({
            showCaption: false, showClear: false, containerClass: defaultAlign,
            filledStar: '<span class="tt-icon-star"></span>',
            emptyStar: '<span class="tt-icon-star"></span>'
          }).rating('update', MOP.Utilities.convertMeasureOrder(parseInt(value), 100, 5));
        },

        hideResourceRating: function hideResourceRating(id) {
            $(".tt-js-rating-container-" + id).hide();
        },

        initCustomReservationInputField: function initCustomReservationInputField(res_custom_policy, multiple_id) {

            var customsReservationConfigurations = MOP.config.getInstanceConfig('customsReservationConfigurations');

            _.each(customsReservationConfigurations, function (element, index) {

                if (parseInt(element.enabled, 10) && (element.policy != MOP.constants.FIELD_INVISIBLE) && !MOP.Utilities.empty(res_custom_policy) && res_custom_policy.indexOf(index) != -1) {

                    var fieldType = parseInt(element.properties.type, 10);
                    if (fieldType == 3) {
                        var date_pattern = null;
                        switch (parseInt(element.properties.date_type, 10)) {
                            case MOP.config.constants.INPUT_FIELD_DATE_DDMMYYYY:
                                date_pattern = ['d', 'm', 'Y'];
                                break;
                            case MOP.config.constants.INPUT_FIELD_DATE_MMYYYY:
                                date_pattern = ['m', 'Y'];
                                break;
                            case MOP.config.constants.INPUT_FIELD_DATE_YYYY:
                                date_pattern = ['Y'];
                                break;
                        }

                        var selector = (multiple_id ? multiple_id + "_" : "") + index;
                        var cleave = new Cleave('#' + selector, {
                            date: true,
                            datePattern: date_pattern
                        });
                    }
                }
            });

        },
        activateClickOnCustomFieldWithLinkDescription: function (res_custom_policy, multiple_id) {
            var _this = this;
            var customsReservationConfigurations = MOP.config.getInstanceConfig('customsReservationConfigurations');

            var model = {};
            _.each(customsReservationConfigurations, function (element, index) {
                model = {};
                if (parseInt(element.enabled, 10) && (element.policy != MOP.constants.FIELD_INVISIBLE) && !MOP.Utilities.empty(res_custom_policy) && res_custom_policy.indexOf(index) != -1) {
                    var fieldType = parseInt(element.properties.type, 10);

                    if (fieldType == 0) {
                        if (!MOP.Utilities.empty(element.properties.description_link) && !MOP.Utilities.empty(element.properties.description)) {
                            model.description_link = element.properties.description_link;
                            if (multiple_id) model.multiple_id = multiple_id;
                            model.custom_field = index;

                            //{{#if multiple_id}}{{multiple_id}}_{{/if}}{{custom_field}}_link
                            var elementid = "";
                            if (model.multiple_id) elementid = elementid + model.multiple_id + "_"
                            elementid = model.custom_field;

                            // Prima di fare il bind del click, faccio l'unbind perchè l'onshow in questa pagina viene chiamato due volte (per una ragione non chiara)
                            // e quindi partirebbero due click
                            $("#" + elementid + "_link").unbind('click');
                            $("#" + elementid + "_link").click(function (e) {
                                e.preventDefault();

                                if ($("#" + elementid + "_description").is(":visible")) {
                                    $("#" + elementid + "_description").hide();
                                } else {
                                    $("#" + elementid + "_description").show();
                                }
                                _this.resizeThisFrame();
                            })
                        }
                    }
                }
            });

        },

      showCustomUploadDocumentField: function (res_custom_policy) {

        // Upload document counter, ci può essere solo 1 elemento di tipo upload in pagina se c'è ne sono due triggherimo un errore
        let uploadDocumentsFields = [];

        _.each(MOP.config.getInstanceConfig('customsReservationConfigurations'), function (element, index) {
          if (parseInt(element.enabled, 10) && (element.policy != MOP.constants.FIELD_INVISIBLE) && !MOP.Utilities.empty(res_custom_policy) && res_custom_policy.indexOf(index) != -1) {
            var fieldType = parseInt(element.properties.type, 10);

            if (fieldType === 4) {
              // Creare componente
              const field = {
                mandatory: element.policy == MOP.constants.FIELD_COMPULSORY,
                title: element.properties.description_title,
                description: element.properties.description,
                label: element.properties.label,
                id: index
              }

              uploadDocumentsFields.push(field);
            }
          }
        })

        return uploadDocumentsFields;
      },

        showCustomReservationsFields: function (view_istance, res_custom_policy, multiple_id) {

            var customsReservationConfigurations = MOP.config.getInstanceConfig('customsReservationConfigurations');
            var _template_load = require('templates/common-availabilities-search/templates')
            var template = Handlebars.loadRightTemplate('summary-custom-fields');
            var model = {};

            _.each(customsReservationConfigurations, function (element, index) {
                model = {};
                if (parseInt(element.enabled, 10) && (element.policy != MOP.constants.FIELD_INVISIBLE) && !MOP.Utilities.empty(res_custom_policy) && res_custom_policy.indexOf(index) != -1) {
                    var fieldType = parseInt(element.properties.type, 10);

                    if (fieldType === 4) {
                      return;
                    }

                    var fieldEditable = (element.policy == MOP.constants.FIELD_COMPULSORY) ? true : (element.properties.editable == 0 ? false : true);

                    model.customLabel = TTPolyglot.t(element.specific);
                    model.custom_field = index;
                    if (multiple_id) model.multiple_id = multiple_id;
                    model.description_title = element.properties.description_title;
                    model.description = element.properties.description;
                    if (!MOP.Utilities.empty(element.properties.chars_limit)) {
                        model.chars_limit = element.properties.chars_limit;
                    }

                    if (!fieldEditable) {
                        model.cannot_edit = true;
                    }

                    if (element.policy == MOP.constants.FIELD_COMPULSORY) {
                        model.mandatory = true;
                    }

                    switch (fieldType) {
                        case 0: // input
                            model.inputFieldType = true;
                            model.placeholder = element.properties.placeholder;
                            if (element.properties.chars_type == 1) {
                                model.chars_type = "onkeypress='return event.charCode >= 48 && event.charCode <= 57'";
                            }
                            //Se entrambi non sono vuoti allora è la visualizzazione estesa
                            if (!MOP.Utilities.empty(element.properties.description_link) && !MOP.Utilities.empty(element.properties.description)) {
                                model.description_link = element.properties.description_link;
                            }
                            break;
                        case 1: // textarea
                            model.textareaFieldType = true;
                            model.placeholder = element.properties.placeholder;
                            break;
                        case 2: // select
                            var options = element.properties.options;
                            var selectFieldOptions = {};

                            model.selectFieldType = true;
                            options = options.split(',');

                            _.each(options, function (v, i) {
                                var option = {};

                                option.label = v.trim(); //Rimuovo i possibili spazi prima e dopo la stringa
                                selectFieldOptions[i] = option;
                            });

                            model.selectFieldOptions = selectFieldOptions;
                            break;
                        case 3: // date
                            model.dataFieldType = true;
                            switch (parseInt(element.properties.date_type, 10)) {
                                case MOP.config.constants.INPUT_FIELD_DATE_DDMMYYYY:
                                    model.placeholder = element.properties.placeholder + " (" + TTPolyglot.t('ddmmyyyy', null, true) + ")";
                                    model.date_validator = 10;
                                    break;
                                case MOP.config.constants.INPUT_FIELD_DATE_MMYYYY:
                                    model.placeholder = element.properties.placeholder + " (" + TTPolyglot.t('mmyyyy', null, true) + ")";
                                    model.date_validator = 7;
                                    break;
                                case MOP.config.constants.INPUT_FIELD_DATE_YYYY:
                                    model.placeholder = element.properties.placeholder + " (" + TTPolyglot.t('yyyy', null, true) + ")";
                                    model.date_validator = 4;
                                    break;
                            }
                            break;
                    }

                    // Il multiple_id mi serve per le multiple per poter riconoscere
                    var custom_fields_container = (multiple_id ? multiple_id + "_" : "") + "custom_fields_container";
                    var custom_fields = (multiple_id ? multiple_id + "_" : "") + "custom_fields";

                    if ($("#" + custom_fields_container).is(":visible") == false) {
                        MOP.TM.show(view_istance.$el.find('#' + custom_fields_container));
                    }

                    view_istance.$el.find('#' + custom_fields).append(template(model));

                }

            });

        },

        validateCustomReservationFields: function validateCustomReservationFields(res_custom_policy, multiple_id = null, custom) {

            var customsReservationConfigurations = MOP.config.getInstanceConfig('customsReservationConfigurations');

            var missing_custom_fields = [];
            _.each(customsReservationConfigurations, function (element, index) {

                if (parseInt(element.enabled, 10) && element.policy == MOP.constants.FIELD_COMPULSORY && !MOP.Utilities.empty(res_custom_policy) && res_custom_policy.indexOf(index) != -1) {

                    var selector = (multiple_id ? multiple_id + "_" : "") + index;

                    var fieldType = parseInt(element.properties.type, 10);
                    switch (fieldType) {
                        case 0: // input
                        case 1: // textarea
                            if ($("#" + selector).length && $("#" + selector).val().trim() == "") {
                                missing_custom_fields.push(element.properties.description_title);
                            }
                            break;
                        case 2: // select
                            if (MOP.config.isMobile()) {
                                if ($("#" + selector).length && $("#" + selector).text().trim() == "") {
                                    missing_custom_fields.push(element.properties.description_title);
                                }
                            } else {
                                if ($('#' + selector).val() == null || $('#' + selector).val() == "") {
                                    missing_custom_fields.push(element.properties.description_title);
                                }
                            }
                            break;
                        case 3: // date 
                            if ($("#" + selector).length && $('#' + selector).val().length < parseInt($('#' + selector).attr("date-validator"))) {
                                missing_custom_fields.push(element.properties.description_title);
                            }
                            break;
                        case 4:
                          if (
                            !MOP.isAdminLoggedIn()
                            &&
                            multiple_id === null
                            &&
                            !MOP.Utilities.empty(MOP.CustomFieldUploadWidget)
                            &&
                            ( ((index in MOP.CustomFieldUploadWidget) && MOP.CustomFieldUploadWidget[index].data === null)
                              ||
                              !(index in MOP.CustomFieldUploadWidget) )
                          ) {
                            missing_custom_fields.push(element.properties.description_title);
                          }
                          break;
                    }

                }
            });

            var error_msg = "";
            if (missing_custom_fields.length != 0) {
                error_msg = missing_custom_fields.join(", ");
                var alert = { type: "danger", msg: TTPolyglot.t('Appointment Mandatory Additional Info', null, true) + " " + error_msg };
                MOP.execute("MOP:alert", alert);
            }

            return error_msg;

        },

        renderPrescriptionFields: function renderPrescriptionFields(view_istance) {
            var prescriptionPolicy = MOP.config.getInstanceConfig('prescriptionPolicy');
            var prescriptionFields = 0;

            _.each(view_istance.$el.find('[data-prescription-config]'), function (elem, index) {
                var prescription_config = $(elem).attr('data-prescription-config');

                if (prescription_config == 'mopPriority' && !MOP.config.isPrescriptionPriorityConvenzionato()) {
                    prescriptionPolicy[prescription_config] = 0;
                }

                switch (parseInt(prescriptionPolicy[prescription_config], 10)) {
                    case MOP.config.constants.FIELD_INVISIBLE:
                        MOP.TM.hide(elem);
                        break;
                    case MOP.config.constants.FIELD_VISIBLE:
                        prescriptionFields++;
                        break;
                    case MOP.config.constants.FIELD_COMPULSORY:
                        $(elem).addClass('tt-input-container--required');
                        prescriptionFields++;
                        break;
                }
            });

            if (prescriptionFields == 0) {
                MOP.TM.hide(view_istance.$el.find('#prescriptionContainer'));
            }

            return prescriptionFields;
        },

        validatePrescriptionFields: function validatePrescriptionFields(attrs) {

            var errors = {};

            if (MOP.config.isPrescriptionPriorityConvenzionato()) {
                if (attrs.prescription_code == '' && MOP.config.getPrescriptionCode() == parseInt(MOP.config.constants.FIELD_COMPULSORY)) {
                    errors.prescription_code = TTPolyglot.t('Prescription Code Required');
                }

                if (attrs.prescription_priority == '' && MOP.config.getPrescriptionPriorityValue() == parseInt(MOP.config.constants.FIELD_COMPULSORY)) {
                    errors.prescription_priority = TTPolyglot.t('Prescription Priority Required');
                }

                if (attrs.prescription_doctor == '' && MOP.config.getPrescriptionDoctor() == parseInt(MOP.config.constants.FIELD_COMPULSORY)) {
                    errors.prescription_doctor = TTPolyglot.t('Prescription Doctor is Required');
                }

                if (attrs.prescription_summary == '' && MOP.config.getPrescriptionSummaryValue() == parseInt(MOP.config.constants.FIELD_COMPULSORY)) {
                    errors.prescription_summary = TTPolyglot.t('Prescription Diagnosis Required');
                }
            }

            return errors;

        },

        isMenuPageVisibileForUserRole: function isMenuPageVisibileForUserRole (page) {
            var pageProperties = MOP.config.getInstanceConfig(MOP.config.isMobile() ? 'menuConfiguration' : 'desktopMenuConfiguration')[page];

            if (!pageProperties) return false;

            var response = false;

            
            // Fix momentaneo in attesa che venga aggiunta una colonna nel BO solo per le risorse avanzate
            if (page == 'myReservations' && MOP.isAdminLoggedIn() && MOP.Utilities.empty(MOP.getLoggedUserDataField("is_allowed_to_access_my_reservations_page"))) {
              return response = false;
            }

            // Fix momentaneo: tutte le risorse devono poter vedere la pagina "contatta medico" 
            // secondo il config delle risorse base.
            // ticket di riferimento: https://tuotempo.freshdesk.com/a/tickets/77943
            if(page == 'talkToDoctor' && MOP.isResourceLoggedIn()) {
              return response = !MOP.Utilities.empty(pageProperties["visibleForBaseResource"]);
            }

            if (MOP.isBaseResourceLoggedIn()) {
                response = !MOP.Utilities.empty(pageProperties["visibleForBaseResource"]);
            } else if (MOP.isAdminLoggedIn()) {
                response = !MOP.Utilities.empty(pageProperties["visibleForOperator"]);
            } else {
                response = !MOP.Utilities.empty(pageProperties["visible"]);
            }

            return response;
        },

        getMenuPageTitle: function getMenuPageTitle(page, fallbackTitle) {

            var pageProperties = MOP.config.getInstanceConfig(MOP.config.isMobile() ? 'menuConfiguration' : 'desktopMenuConfiguration')[page];
            var title = TTPolyglot.t(fallbackTitle);

            if (!MOP.Utilities.empty(pageProperties) && !MOP.Utilities.empty(pageProperties['title'])) {
                title = pageProperties['title'];
            }

            return title;
        },

        getMenuPageCustomValue: function getMenuPageCustomValue(page, custom) {

            var pageProperties = MOP.config.getInstanceConfig(MOP.config.isMobile() ? 'menuConfiguration' : 'desktopMenuConfiguration')[page];

            if (pageProperties['custom']) {
                return pageProperties['custom'][custom];
            }

            return false;
        },

        getMenuPageUrl: function getMenuPageUrl(page) {

            var pageProperties = MOP.config.getInstanceConfig(MOP.config.isMobile() ? 'menuConfiguration' : 'desktopMenuConfiguration')[page];

            if (!MOP.Utilities.empty(pageProperties['url'])) {
                return pageProperties['url'];
            }

            return '';
        },

        isMenuPageKeySet: function isMenuPageKeySet(page, key) {

            var pageProperties = MOP.config.getInstanceConfig(MOP.config.isMobile() ? 'menuConfiguration' : 'desktopMenuConfiguration')[page];

            if (!MOP.Utilities.empty(pageProperties[key])) {
                return true;
            }

            return false;
        },

        isMenuPageLoginRequired: function isMenuPageLoginRequired(page) {

            var pageProperties = MOP.config.getInstanceConfig(MOP.config.isMobile() ? 'menuConfiguration' : 'desktopMenuConfiguration')[page];

            if (!pageProperties) return false;

            if (pageProperties && pageProperties['login_required'] == 1) {
                return true;
            }

            return false;
        },

        /*
          elemType:
            - 0 Input
            - 1 Checkbox
            - 2 Nested
          msgType:
            - 0 Error
            - 1 Info
        */
        markField: function markField(elem,msg,elemType,isValid, msgType) {
          var field = elem;
          switch (elemType) {
            case 0:
              field = elem.parent();
              break;
            case 1:
              field = elem.parent().parent();
              break;
            default:
              break;
          }
          
          var msgClass = '';

          if (msgType == 0) {
            msgClass = 'tt-input-error';
          } else if (msgType == 1) {
            msgClass = 'tt-deprecated-input-info';
          }

          //Facciamo pulizia a prescindere
          field.next('.'+msgClass).remove();
  
          if (isValid) {
            field.removeClass('tt-deprecated-input--validation-error');
          } else {
            field.addClass('tt-deprecated-input--validation-error');
            var error_help = document.createElement('span');
            error_help.className = msgClass;
            error_help.innerHTML = msg;
            field.after(error_help)
          }
        },
        scrollToJqueryElement: function ($el, offsetToAdd) {
          $([document.documentElement, document.body]).animate({
            scrollTop: $el.offset().top - offsetToAdd
          }, 500);
        },
        liveCommunicationReservationFieldCheck: function (view) {
          if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('addReservationAdditionalFields')) && !MOP.isAdminLoggedIn()) {
            const {communicationPhone, communicationEmail} = MOP.config.getInstanceConfig('addReservationAdditionalFields');
            const {phone, email} = MOP.getLoggedUserData();

            if (parseInt(communicationPhone) == MOP.config.constants.FIELD_VISIBLE && !MOP.Utilities.empty(phone)) {
            view.find('.js-summary-communication-phone').keyup(function(e) {
                const currentTarget = MOP.TM.getCurrentTarget(e);
                if (currentTarget.value.trim() === '') {
                  MOP.TM.markField(
                    $(".js-summary-communication-phone"), 
                    TTPolyglot.t("Mop Specify Contact Info"),
                    0,
                    false,
                    1
                  )
                } else {
                  MOP.TM.markField($(".js-summary-communication-phone"), '',0,true, 1)
                }
              });
            }
            if (parseInt(communicationEmail) == MOP.config.constants.FIELD_VISIBLE  && !MOP.Utilities.empty(email)) {
              view.find('.js-summary-communication-email').keyup(function(e) {
                const currentTarget = MOP.TM.getCurrentTarget(e);
                if (currentTarget.value.trim() === '') {
                  MOP.TM.markField(
                    $(".js-summary-communication-email"), 
                    TTPolyglot.t("Mop Specify Contact Info"),
                    0,
                    false,
                    1
                  )
                } else {
                  MOP.TM.markField($(".js-summary-communication-email"), '',0,true, 1)
                }
              });
            }
          }
        },
        submitCommunicationReservationFieldCheck: function () {

          let doSubmit = true;

          if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('addReservationAdditionalFields')) && !MOP.isAdminLoggedIn()) {
            const {communicationPhone, communicationEmail} = MOP.config.getInstanceConfig('addReservationAdditionalFields');
            const $communicationPhoneField = $(".js-summary-communication-phone");
            const $communicationEmailField = $(".js-summary-communication-email");
            if (parseInt(communicationPhone) == MOP.config.constants.FIELD_COMPULSORY &&
                MOP.Utilities.empty($communicationPhoneField.val())) {
              MOP.TM.markField(
                $communicationPhoneField, 
                TTPolyglot.t("Mop Specify Contact Mandatory"),
                0,
                false,
                0
              )
              doSubmit = false;
            }
            if (parseInt(communicationEmail) == MOP.config.constants.FIELD_COMPULSORY &&
                MOP.Utilities.empty($communicationEmailField.val())) {
              MOP.TM.markField(
                $communicationEmailField, 
                TTPolyglot.t("Mop Specify Contact Mandatory"),
                0,
                false,
                0
              )
              doSubmit = false;
            }
  
            if (doSubmit === false) {
              MOP.TM.scrollToJqueryElement($('.js-summary-communication-container'), MOP.config.isMobile() ? 50 : 0);
            }
          }

          return doSubmit;
        }
    };

    return TM;
};
