import MOP from 'app';

import { 
  DOSSIER_BIOMETRICS_NOT_ACTIVE,
  DOCUMENT_OPENABLE_TYPE,
  DOCUMENT_EXPIRED_TYPE,
  DOCUMENT_NOT_DOWNLOADABLE_TYPE
} from 'new-dossier-common/constants';

import { getDossierAuthObject } from 'common-mop/dossier';

import { TTPolyglot } from "@frontend/tt-polyglot";

export const isTheCurrentUserAuthenticated = _userid => {
  if (!isDossierAuthValid(_userid)) {
    return false;
  }
  return true;
};

export const isDossierAuthValid = _userid => {
  // L'OTP vince sulla subsession se il config è attivo, altrimenti ricadiamo nella subsession (che è solo per l'APP)
  let otp_subsession = false;

  if (!MOP.Utilities.empty(dossierOTPVerificationConfig())) {
    otp_subsession = isDossierOTPVerifcationValid();
  } else {
    otp_subsession = isDossierSubSessionValid();
  }

  // Se ho appena inserito l'otp inviato al legacy phone, non richiedo l'otp classico 
  const loggedUser = MOP.getLoggedUser();
  const parents_info = loggedUser.get('parents_info');
  
  let has_legacy_otp = false;

  if (MOP.getLoggedUserId() == _userid) {
    has_legacy_otp = loggedUser.get('legacy_otp_validated');

    loggedUser.set('legacy_otp_validated', false);
  } else if (!MOP.Utilities.empty(parents_info) && parents_info[_userid]) {
    has_legacy_otp = parents_info[_userid].legacy_otp_validated;

    parents_info[_userid].legacy_otp_validated = false;

    loggedUser.set('parents_info', parents_info);
  }

  if (has_legacy_otp) {
    otp_subsession = true;
  }

  return MOP.getLoggedUser()._isDossierPrivacyValid(_userid) && MOP.getLoggedUser()._isDossierTwoFactorValid(_userid) && otp_subsession;
};

export const isDossierOTPVerifcationValid = () => {

  if (MOP.permanentLocalStorage.has('dossierAuth')) {
    const { otp_expiration } = JSON.parse(MOP.permanentLocalStorage.get('dossierAuth'));
    if (!MOP.Utilities.empty(otp_expiration) && otp_expiration + 1800 > MOP.Utilities.getTimestamp()) {
      return true;
    }
  }
  return false;
};

export const isDossierSubSessionValid = () => {

  if (MOP.Utilities.isMobileApp()) {
    if (MOP.permanentLocalStorage.has('dossierAuth')) {
      const { subsession_expiration } = JSON.parse(MOP.permanentLocalStorage.get('dossierAuth'));
      if (!MOP.Utilities.empty(subsession_expiration) && subsession_expiration + 3600 > MOP.Utilities.getTimestamp()) {
        return true;
      }
    }
    return false;
  }

  return true;
};

export const submitOTPVerification = (dossier_otp, userid, forceOtp) => {

  // Funzione inserita in common e non nelle action perchè comanda un aggiornamento di stato interno al componente OTP,
  // Se l'avessimo messa dentro una action poi con il dispatch avrebbe aggiornato troppe cose, mentre noi vogliamo renderizzare
  // solo il componente, poi l'alert delle biometrics si occupa di fare il refresh completo dello stato di redux


  return new Promise((res, rej) => {

    if (MOP.Utilities.empty(dossier_otp)) {
      const alert = { type: 'danger', msg: TTPolyglot.t('Dossier Fields Alert') };
      MOP.execute('MOP:alert', alert);
      return rej(false);
    }

    if (forceOtp) {
      // Se proveniamo da un forceOtp dobbiamo aggiornare la localstorage andando ad aggiungere un tentativo di submit dell'otp
      // Oppure dobbiamo fare redirect sulla privacy se i tentativi sono maggiori di due
      setForceOtpAttempts();
    }

    MOP.getLoggedUser()._confirmOTPVerification(userid, dossier_otp)
      .then(() => {
        setForceOtpAttempts(true);
        if (forceOtp) {
          enableStorageBiometrics();
          const alert = { type: "success", msg: TTPolyglot.t("OTP Dossier Done") };
          return MOP.changePage("profile", "profile/advanced_options", null, null, alert);
        }
        res(true);
      })
      .catch(error => {

        const workAuthObject = getDossierAuthObject();

        if (workAuthObject.force_otp_attempts >= 2) {
          setForceOtpAttempts(true);
          const alert = { type: "warning", msg: TTPolyglot.t("OTP Max Retry Attempts") };
          return MOP.changePage("profile", "profile/advanced_options", null, null, alert);
        }


        const alert = { type: 'danger', msg: TTPolyglot.t(error.msg) };
        MOP.execute('MOP:alert', alert);
        rej(false);
      });


  });

};

export const isBiometricsAvailable = () => {
  return new Promise((resolve, reject) => {
    Fingerprint.isAvailable(
      type => {
        resolve(type);
      }, // type returned to success callback: 'face' on iPhone X, 'touch' on other devices
      msg => {
        reject(false);
      } // error handler: no TouchID available
    );
  });
};

export const initBiometrics = () => {

  return new Promise((resolve, reject) => {

    Fingerprint.show({
      description: TTPolyglot.t("MOP Enter Login Password")
    }, () => {
      resolve(true);
    }, () => {
      reject(false);
    });

  });


};

export const episodeHasNewDocuments = episode => {

  return episode.downloads.some(downloadObj => {
    const created = MOP.Utilities.humanDateToDateObj(MOP, downloadObj.created);
    created.setHours(0, 0, 0, 0);
    const diff = (created.getTime() / 1000) > (MOP.Utilities.getTimestamp() - MOP.constants.NEW_DOCUMENT_DOSSIER_THRESHOLD);
    return downloadObj.downloads === "0" && diff;
  });

};

export const documentIsNewDocument = document => {
  const created = MOP.Utilities.humanDateToDateObj(MOP, document.created);
  created.setHours(0, 0, 0, 0);
  const diff = (created.getTime() / 1000) > (MOP.Utilities.getTimestamp() - MOP.constants.NEW_DOCUMENT_DOSSIER_THRESHOLD);
  return !document.expired && document.downloads === "0" && diff;
};

export const createYearEpisodesArray = episodes => {

  // Per capire perchè uso un oggetto e non un array leggi il commento sotto (1)
  const allEpisodes = {};
  episodes.map(episode => {

    const yearKey = episode.start_year;
    if (!allEpisodes[yearKey]) {
      allEpisodes[yearKey] = [];
    }

    // Controllo se l'episodio ha nuovi documenti
    // const hasNewDocument = episodeHasNewDocuments(episode);

    // (1): qui inserisco l'episodio nell'array del relativo anno.
    // Se avessi avuto typeOf(allEpisodes) === Array invece: 
    // questo passaggio sarebbe stato più complicato perchè avrei dovuto scorrere 
    // tutto allEpisodes per trovare l'anno giusto nel quale inserire l'episodio.
    // con gli oggetti queste iterazioni sono gratis visto che accedo alle chiavi con complessità O(1) 
    // mentre per scorrere l'array avrei avuto complessità O(n)
    allEpisodes[yearKey].push({ ...episode });
  });


  // Sorto l'array degli anni e lo pusho in episodesArray che conterrà quindi l'array in ordine decrescente di anno
  const episodesArray = Object.keys(allEpisodes).sort((x, y) => {
    return parseInt(y) - parseInt(x);
  }).reduce((acc, val) => {
    acc.push({ year: val, episodes: allEpisodes[val] });
    return acc;
  }, []);

  return episodesArray;
};

export const getDossierSynced = user => {

  // Se l'istanza NON è integrata allora non andiamo a utilizzare il flusso del dossier_syncd perchè
  // non abbiamo flussi di sincronizzazione esterni ma è tutto interno, quindi torniamo sempre
  // il timestamp corrente
  if (!MOP.config.isDossierIntegrated(MOP)) {
    return MOP.Utilities.getTimestamp();
  }

  return user.dossier_syncd;
};

export const isGroupEpisodesByTagActive = () => {
  return MOP.config.getInstanceConfig('groupEpisodesByTag');
};

export const dossierPrivacyPolicyConfig = () => {
  return MOP.config.getInstanceConfig('dossierPrivacyPolicy');
};

export const dossierParentsPrivacyPolicyConfig = () => {
  return MOP.config.getInstanceConfig('dossierParentsPrivacyPolicy');
}

export const isParentsDossierEnabledWithManualUpdate = () => {
  return parseInt(MOP.config.getInstanceConfig('parentsDossierEnabled')) === 2;
};

export const isBiometricsConfigActive = () => {
  return parseInt(MOP.config.getInstanceConfig('enableBiometricRecognition')) === 1;
};

// TODO Pierpaolo, questo config viene usato anche in altri posti oltre al dossier, non ricordo dove va spostato lib/common?
export const dossierOTPVerificationConfig = () => {
  return MOP.config.getInstanceConfig("dossierOTPVerification");
};


// TODO Pierpaolo, questo config viene usato anche in altri posti oltre al dossier, non ricordo dove va spostato lib/common?
export const isParentsDossierEnabled = () => {
  return MOP.config.getInstanceConfig('parentsDossierEnabled');
};

export const isParentsPrivacyPolicyEnabled = () => {
  return MOP.config.getInstanceConfig('showDossierParentsPrivacyPolicy');
}


export const isDossierDropdownEnabled = () => {
  return !MOP.Utilities.empty(MOP.config.getInstanceConfig('parentsDossierEnabled'));
};


export const displayError = (param, error, removeError = false) => {
  const field = param.parent();

  if (removeError) {
    // Rimuovo errore
    field.next('.tt-input-error').remove();
    field.removeClass('tt-deprecated-input--validation-error');
  } else {
    // Mostro errore
    field.next('.tt-input-error').remove();
    field.removeClass('tt-deprecated-input--validation-error');
    field.addClass('tt-deprecated-input--validation-error');
    const error_help = document.createElement('span');
    error_help.className = "tt-input-error";
    error_help.innerHTML = error;
    error_help.dataset.e2eid = "dossier-document-access-code-error";
    field.after(error_help);
  }
};


export const getDefaultAuthObject = () => {

  // La subsession e l'otp sono mutualmente esclusivi, ovvero se è attivo l'uno non sarà attivo l'altro

  return {
    "subsession": MOP.Utilities.empty(dossierOTPVerificationConfig()),
    "otp": !MOP.Utilities.empty(dossierOTPVerificationConfig()),
    "biometrics_active": DOSSIER_BIOMETRICS_NOT_ACTIVE,
    "otp_expiration": null,
    "subsession_expiration": null,
    "force_otp_attempts": 0
  };

};

export const setForceOtpAttempts = (reset = false) => {
  const workAuthObject = getDossierAuthObject();
  if (reset) {
    workAuthObject.force_otp_attempts = 0;
  } else {
    workAuthObject.force_otp_attempts = 1 + workAuthObject.force_otp_attempts;
  }

  MOP.permanentLocalStorage.set('dossierAuth', JSON.stringify(workAuthObject));
};

export const enableStorageBiometrics = () => {

  const workAuthObject = getDossierAuthObject();

  workAuthObject.biometrics_active = 1;

  MOP.permanentLocalStorage.set('dossierAuth', JSON.stringify(workAuthObject));

};

/** getDocumentOpenableType
 * * questa funzione riceve in ingresso un documento e torna il tipo di documento, se esso è apribile, non scaricabile o scaduto
 * @param { Document } - documento
 * @return { DOCUMENT_OPENABLE_TYPE | DOCUMENT_EXPIRED_TYPE | DOCUMENT_NOT_DOWNLOADABLE_TYPE } - tipo del documento
 */
export const getDocumentOpenableType = ({expired, is_downloadable, ondemand}) => {
  
  // Se il documento è scaduto torniamo DOCUMENT_EXPIRED_TYPE
  if(!MOP.Utilities.empty(parseInt(expired))) {
    return DOCUMENT_EXPIRED_TYPE;
  }

  // Se il documento ha la chiave is_downloadable vuota ed anche la chiave ondemand vuota, allora non è scaricabile
  if(MOP.Utilities.empty(parseInt(is_downloadable)) && MOP.Utilities.empty(parseInt(ondemand))) {
    return DOCUMENT_NOT_DOWNLOADABLE_TYPE;
  }

  // in tutti gli altri casi può essere aperto (consideriamo i documenti ondemand apribili anche se hanno la chiave is_downloadable vuota)
  return DOCUMENT_OPENABLE_TYPE;
  
};